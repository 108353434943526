import {
  Button,
  Card,
  Label,
  Modal,
  Select,
  Spinner,
  TextInput,
} from "flowbite-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { UseSignupMemberInfo, UseUserTypes } from "../services/Query";
import sign_up_logo from "../assets/images/sign_up_logo.png";
import mail_icon from "../assets/images/mail_icon.svg";
import user_icon from "../assets/images/user_icon.svg";
import success_icon from "../assets/images/success_icon.svg";
import warn_icon from "../assets/images/warning_icon.svg";
import lock_icon from "../assets/images/lock-privacy.svg";
import show_icon from "../assets/images/password_show.svg";
import hide_icon from "../assets/images/password_hide.svg";
import edit_icon from "../assets/images/edit_icon.svg";
import download_logo from "../assets/images/download_logo.png";
import windows_logo from "../assets/images/windows_logo.svg";
import appicon_logo from "../assets/images/logo_icon.png";
type userFrm = {
  id: string;
  avatar_id: string;
  firstName: string;
  lastName: string;
  email: string;
  confirm_email: string;
  password: string;
  confirm_password: string;
  nickname: string;
  avatar_url: string;
  privilege_id: string | null;
  usersetting_id: string | null;
  worldVolume: number | null;
  voiceVolume: number | null;
  mic: number | null;
  streetNumber: string | null;
  streetName: string | null;
  town: string | null;
  stateProvince: string | null;
  country: string | null;
  height: number | null;
  eligibleForNameChange: boolean;
};
const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;
export const Download = () => {
  const { mutate: saveMember, isLoading: isSaving } = UseSignupMemberInfo();
  const { data: privileges, isLoading: isPrivilegeData } = UseUserTypes();
  const [showMessage, setShowMessage] = useState(false);

  const {
    register,
    getValues,
    reset,
    watch,
    formState: { isValid, dirtyFields, errors },
  } = useForm<userFrm>({
    mode: "onChange",
  });

  return (
    <div className="flex items-center py-3 font-nunito md:md-h-vw-port xl:xl-h-vw-port">
      <div className="flex justify-center w-full py-8 space-x-10">
        <div className="w-[860px] space-y-8">
          <div className="flex justify-center">
            <img src={download_logo} alt="" />
          </div>
          <h6 className="text-center text-[#0098DB] font-bold text-[40px]">
            Download The Application Installer
          </h6>
          <p className="text-[#232429] text-center font-normal text-lg">
            We are excited to share a pre-release version of Serenaverse for
            testing! <br />
            Please note that this is a beta version with limited
            functionality, and you may encounter issues as we continue to refine
            the experience. Your feedback is essential in helping us improve, so
            if you run into any bugs or problems, kindly let us know.
          </p>
          <div className="flex justify-center">
            <div className="border border-[#0098DB] w-[308px] h-[300px] items-between grid content-between">
              <h6 className="bg-[#0098DB] w-full flex justify-center text-white py-3 space-x-2">
                <div>
                  <img src={windows_logo} alt="" />
                </div>
                <div>Windows</div>
              </h6>
              <div className="flex justify-center">
                <img src={appicon_logo} alt="" width={100} />
              </div>
              <div className="p-3 space-y-5">
                <div className="flex justify-center">
                  <button
                    className="bg-[#0098DB] text-white text-center w-full py-2"
                    onClick={() => {
                      setShowMessage(true);
                    }}
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showMessage}
        size={"xl"}
        onClose={() => setShowMessage(false)}
      >
        <Modal.Header
          theme={{
            base: "flex items-start justify-between rounded-t p-5 dark:border-gray-600",
          }}
        >
        </Modal.Header>
        <Modal.Body className="space-y-4 font-nunito">
          <div className="text-[#0098DB] text-xl font-bold text-center">
            Steps to Download and Install Serenaverse (Beta)
          </div>
          <div>
            <ol className="px-4 list-decimal">
              <li className="font-semibold mb-2">
                Download the ZIP File <br />
                <p className="font-normal">Click the "Download" button below to download the Serenaverse beta ZIP file from our site.</p>
                <p className="font-normal mt-2 ps-4">
                  ⚠️ <span className="font-semibold">Important:</span> Please note that some anti-virus software, such as the "Real-Time Protection" setting in Windows, may stop the download. 
                  If the download is interrupted, check your anti-virus settings and temporarily disable real-time protection to complete the download safely.
                </p>
              </li>
              <li className="font-semibold mb-2">
                Save to Your Local Drive <br />
                <span className="font-normal">Choose a location on your computer to save the downloaded file (e.g., your Desktop or Downloads folder).</span>
              </li>
              <li className="font-semibold mb-2">
                Expand the ZIP File <br />
                <span className="font-normal">
                  If the file doesn't automatically unzip, locate the downloaded file (InstallerSerenaverse-v1.04b.zip) and double-click it to
                  expand the contents.
                </span>
              </li>
              <li className="font-semibold mb-2">
                Install the Beta Version 
                <span className="font-normal">
                  Once the ZIP is expanded, follow the
                  on-screen prompts to complete the installation.
                </span>
              </li>
            </ol>
          </div>
          <div className="flex justify-center space-x-4">
            <a
              href={`${backend_baseUrl}/download/InstallerSerenaverse-v1.04b.zip`}
              className="bg-[#0098DB] text-white text-center px-5 py-2 rounded-md text-lg font-bold"
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
            <button
              onClick={() => setShowMessage(false)}
              className="bg-[#66B929] rounded-md text-white px-8 py-2 font-bold text-lg disabled:opacity-45"
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
