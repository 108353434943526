import React, { Suspense, lazy } from "react";
import "./App.css";
import ReactLoading from "react-loading";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";
import { QueryClient, QueryClientProvider } from "react-query";
import { signUser } from "./store";
import { useRecoilValue } from "recoil";
import { Landing } from "./pages/Landing";
import { CreatePassword } from "./pages/CreatePassword";
import { ResetPassword } from "./pages/ResetPassword";
import { ForgetPassword } from "./pages/ForgetPassword";
import { ProfileDs } from "./pages/ProfileDs";
import { Download } from "./pages/Download";
import { Agreement } from "./pages/Agreement";
import { EmailConfirm } from "./pages/EmailConfirm";
import { News } from "./pages/News";
import { Career } from "./pages/Career";
import { Contact } from "./pages/Contact";
import { Home } from "./pages/Home";
import { FAQ } from "./pages/FAQ";
import { NotFound } from "./pages/NotFound";
import { UpdatePassword } from "./pages/UpdatePassword";
const Signin = lazy(() =>
  import("./pages/Signin").then((module) => ({
    default: module.Signin,
  }))
);

const UserTypes = lazy(() =>
  import("./pages/UserTypes").then((module) => ({ default: module.UserTypes }))
);
const AvatarManage = lazy(() =>
  import("./pages/AvatarManage").then((module) => ({
    default: module.AvatarManage,
  }))
);
const Signup = lazy(() =>
  import("./pages/Signup").then((module) => ({ default: module.Signup }))
);
const LoadStatus = () => {
  return (
    <div className="w-full py-4 text-center">
      <ReactLoading
        type="spinningBubbles"
        className="mx-auto my-auto"
        height={"70px"}
        width={"70px"}
        color="#666666"
      />
    </div>
  );
};
function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 120, // 30seconds
        refetchOnMount: "always",
        refetchOnReconnect: "always",
        refetchOnWindowFocus: "always",
      },
    },
  });
  const userInfo = useRecoilValue(signUser);
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<LoadStatus />}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="" element={<Landing />} />
              <Route path="signin" element={<Signin />} />
              <Route index path="signup" element={<Signup />} />
              <Route index path="landing" element={<Landing />} />
              <Route index path="news" element={<News />} />
              <Route index path="career" element={<Career />} />
              <Route index path="contact" element={<Contact />} />
              <Route index path="agreement" element={<Agreement />} />
              <Route index path="email_confirm" element={<EmailConfirm />} />
              <Route index path="faqs" element={<FAQ />} />
              <Route
                index
                path="create_password/:token"
                element={<CreatePassword />}
              />
              <Route
                index
                path="reset_password/:token"
                element={<ResetPassword />}
              />
              <Route
                index
                path="forget_password"
                element={<ForgetPassword />}
              />
              <Route index path="download" element={<Download />} />
              <Route index path="profile" element={<ProfileDs />} />
              {userInfo && (
                <>
                  {/* <Route index path="profile" element={<Profile />} /> */}
                  <Route index path="user_types" element={<UserTypes />} />
                  <Route index path="avatars" element={<AvatarManage />} />
                  <Route
                    index
                    path="update_password"
                    element={<UpdatePassword />}
                  />
                  <Route index path="home" element={<Home />} />
                </>
              )}
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </QueryClientProvider>
  );
}

export default App;
