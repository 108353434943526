import { useMutation, useQuery } from 'react-query';
import { Admin } from '../models/Admin';
import {
  SignMember,
  SigninUser,
  createUserPassword,
  getAdminList,
  getSwearwords,
  getUserList,
  getUserTypes,
  googleSignup,
  resetUserPassword,
  saveAdminUser,
  saveMemberUser,
  saveProfileInfo,
  saveSwearword,
  saveUserPType,
  sendContactEmail,
  sendForgetPasswordEmail,
  sendPartnerEmail,
  signupMember,
  synToken,
  updateNickName,
} from './HttpServices';
import TokenService from '../store/token';
import { SignDto, UserDto } from '../models/Dto';
import { Member, UserPrivilegeType } from '../models/Member';
import { SwearWord } from '../models/Swearword';

export const useLogin = () => {
  return useMutation<Admin, Error, string>(
    'login',
    async (data) => {
      return await googleSignup(data);
    },
    {
      retry: false,
    }
  );
};

export const UseSignin = () => {
  return useMutation<SignDto, Error, string>(
    ['signin'],
    async (data) => {
      return await SigninUser(data);
    },
    {
      retry: false,
    }
  );
};
export const UseSigninMember = () => {
  return useMutation<UserDto, Error, string>(
    ['signin_member'],
    async (data) => {
      return await SignMember(data);
    },
    {
      retry: false,
    }
  );
};
export const UseSaveAdminInfo = () => {
  return useMutation<any, Error, string>(
    ['save_admin_infor'],
    async (data) => {
      return await saveAdminUser(data);
    },
    {
      retry: false,
    }
  );
};
export const UseSaveMemberInfo = () => {
  return useMutation<any, Error, string>(
    ['save_member_infor'],
    async (data) => {
      return await saveMemberUser(data);
    },
    {
      retry: false,
    }
  );
};
export const UseCreateMemberPassword = () => {
  return useMutation<Member, Error, string>(
    ['create_member_password'],
    async (data) => {
      return await createUserPassword(data);
    },
    {
      retry: false,
    }
  );
};
export const UseResetMemberNickName = () => {
  return useMutation<Member, Error, string>(
    ['update_member_nickname'],
    async (data) => {
      return await updateNickName(data);
    },
    {
      retry: false,
    }
  );
};
export const UseResetMemberPassword = () => {
  return useMutation<Member, Error, string>(
    ['reset_member_password'],
    async (data) => {
      return await resetUserPassword(data);
    },
    {
      retry: false,
    }
  );
};
export const UseSignupMemberInfo = () => {
  return useMutation<any, Error, string>(
    ['signup_member_infor'],
    async (data) => {
      return await signupMember(data);
    },
    {
      retry: false,
    }
  );
};
export const UseUserTypeInfo = () => {
  return useMutation<any, Error, string>(
    ['save_user_type_info'],
    async (data) => {
      return await saveUserPType(data);
    },
    {
      retry: false,
    }
  );
};
export const UseSwearwordData = () => {
  return useMutation<any, Error, string>(
    ['save_swear_word_data'],
    async (data) => {
      return await saveSwearword(data);
    },
    {
      retry: false,
    }
  );
};
export const UseUserProfile = () => {
  return useMutation<Member, Error, string>(
    ['save_profile_info'],
    async (data) => {
      return await saveProfileInfo(data);
    },
    {
      retry: false,
    }
  );
};
export const UseSendPartnerEmail = () => {
  return useMutation<any, Error, string>(
    ['send_partner_email'],
    async (data) => {
      return await sendPartnerEmail(data);
    },
    {
      retry: false,
    }
  );
};
export const UseSendContactEmail = () => {
  return useMutation<any, Error, string>(
    ['send_contact_email'],
    async (data) => {
      return await sendContactEmail(data);
    },
    {
      retry: false,
    }
  );
};
export const UseSendForgetPasswordEmail = () => {
  return useMutation<any, Error, string>(
    ['send_forget_password_email'],
    async (data) => {
      return await sendForgetPasswordEmail(data);
    },
    {
      retry: false,
    }
  );
};
export const UseAdminList = () => {
  return useQuery<Admin[] | undefined, Error>(
    ['get_all_admin'],
    async () => {
      return await getAdminList();
    },
    {
      retry: false,
    }
  );
};
export const UseUserTypes = () => {
  return useQuery<UserPrivilegeType[] | undefined, Error>(
    ['get_user_privilege_types'],
    async () => {
      return await getUserTypes();
    },
    {
      retry: false,
    }
  );
};
export const UseSwearword = () => {
  return useQuery<SwearWord[] | undefined, Error>(
    ['get_swear_word'],
    async () => {
      return await getSwearwords();
    },
    {
      retry: false,
    }
  );
};
export const UseUserList = () => {
  return useQuery<Member[] | undefined, Error>(
    ['get_all_members'],
    async () => {
      return await getUserList();
    },
    {
      retry: false,
    }
  );
};
export const UseSynToken = () => {
  return useQuery<any, Error>(
    ['get_syn_token'],
    async () => {
      const res = await synToken();
      if (res) TokenService.updateLocalAccessToken(res.jwt_token);
      return res;
    },
    {
      retry: false,
      refetchInterval: 10 * 60 * 1000,
      refetchIntervalInBackground: true,
    }
  );
};
