import { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { signUser } from "../store";
import { useRecoilState } from "recoil";
import logo from "../assets/images/logo.png";
import mobile_logo from "../assets/images/mobile_logo.png";
import close_icon from "../assets/images/close_white.svg";
import { Drawer, Modal } from "flowbite-react";

export interface ILinkProps {
  title: string;
  link: string;
  selected: string;
  isCurOpen?: boolean;
  decorationCls?: string;
  goLink?: () => void;
}
export const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [sUser, setSignUser] = useRecoilState(signUser);
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const [logoutSuccess, setLogoutSuccess] = useState(false);
  return (
    <header
      className={`md:sticky md:top-0 h-[60px] header_shadow flex items-center justify-between px-8 md:z-10 max-md:bg-[#36A9E0]`}
    >
      <div className="flex justify-between w-full py-5 max-md:hidden">
        <div>
          <img
            src={logo}
            alt=""
            className="cursor-pointer"
            onClick={() => {
              navigate(sUser ? "/home" : "/");
            }}
          />
        </div>
        <div className="flex space-x-8 font-nunito text-[#0098DB] ">
          {sUser ? (
            <>
              <div className="flex items-end">
                <Link
                  to="profile"
                  className={`block ${
                    pathname == "/profile"
                      ? "font-bold text-gray underline"
                      : "font-semibold"
                  } `}
                >
                  Profile
                </Link>
              </div>
              {/* <div className="flex items-end">
                <Link
                  to="/news"
                  className={`block ${
                    pathname == '/news'
                      ? 'font-bold text-gray underline'
                      : 'font-semibold'
                  } `}
                >
                  News
                </Link>
              </div>
              <div className="flex items-end">
                <Link
                  to="/career"
                  className={`block ${
                    pathname == '/career'
                      ? 'font-bold text-gray underline'
                      : 'font-semibold'
                  } `}
                >
                  Careers
                </Link>
              </div> */}
              <div className="flex items-end">
                <Link
                  to="/download"
                  className={`block ${
                    pathname == "/download"
                      ? "font-bold text-gray underline"
                      : "font-semibold"
                  } `}
                >
                  Downloads
                </Link>
              </div>
              {/* <div className="flex items-end">
                <Link
                  to="avatars"
                  className={`block ${pathname == '/avatars' ? 'font-semibold text-gray  underline' : 'font-bold'}`}
                >
                  build avatar
                </Link>
              </div> */}
              <div className="flex items-end">
                <button
                  className="py-0 font-semibold button"
                  onClick={() => {
                    setLogoutConfirm(true);
                  }}
                >
                  Logout
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="flex items-end">
                <Link to="signup" className={`block font-semibold text-gray `}>
                  Signup
                </Link>
              </div>
              {/* <div className="flex items-end">
                <Link to="news" className="block font-semibold text-gray">
                  News
                </Link>
              </div>
              <div className="flex items-end">
                <Link to="career" className="block font-semibold text-gray">
                  Careers
                </Link>
              </div> */}

              <div className="flex items-end">
                <Link to="signin" className={`block font-semibold text-gray `}>
                  Login
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex items-center justify-center w-full md:hidden">
        <div className="flex items-center mx-auto">
          <img
            src={mobile_logo}
            alt="mark"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      </div>
      <div className="absolute top-3 md:hidden">
        <button
          type="button"
          className="block text-white focus:outline-none"
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
            <path
              fillRule="evenodd"
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              fill="#FFFFFF"
            />
          </svg>
        </button>
      </div>
      {isOpen && (
        <Drawer
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          theme={{
            root: {
              base: "fixed z-40 overflow-y-auto left0 bg-[#66B929] text-white transition-transform dark:bg-gray-800 ",
              backdrop: "fixed inset-0 z-30 bg-gray-900/50 dark:bg-gray-900/80",
            },
          }}
        >
          <Drawer.Header
            closeIcon={() => <img src={close_icon} alt="" />}
            theme={{
              inner: {
                closeButton:
                  "absolute end-2.5 top-2.5 flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
                closeIcon: "h-4 w-4",
                titleIcon: "me-2.5 h-4 w-4 hidden",
                titleText:
                  "mb-4 inline-flex items-center text-base font-semibold text-gray-500 dark:text-gray-400",
              },
            }}
          />
          <Drawer.Items
            theme={{
              base: "flex flex-col gap-4 h-[100vh] w-[80vw] mt-[48px]",
            }}
          >
            {sUser ? (
              <>
                <div
                  className={`p-3 ${pathname == "/profile" ? "bg-[#36A9E0]" : ""}`}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <Link
                    to="profile"
                    className={`block font-semibold text-gray `}
                  >
                    Profile
                  </Link>
                </div>
                {/* <div
                  className={`p-3 ${pathname == '/news' ? 'bg-[#36A9E0]' : ''}`}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <Link to="news" className="block font-semibold text-gray">
                    News
                  </Link>
                </div>
                <div
                  className={`p-3 ${pathname == '/career' ? 'bg-[#36A9E0]' : ''}`}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <Link to="career" className="block font-semibold text-gray">
                    Careers
                  </Link>
                </div> */}
                <div
                  className={`p-3 ${pathname == "/download" ? "bg-[#36A9E0]" : ""}`}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <Link to="download" className="block font-semibold text-gray">
                    Downloads
                  </Link>
                </div>
                <div
                  className={`p-3 ${pathname == "/signin" ? "bg-[#36A9E0]" : ""}`}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <button
                    className="block font-semibold text-gray border-0 bg-transparent"
                    onClick={() => {
                      setSignUser(undefined);
                      navigate("/");
                    }}
                  >
                    Logout
                  </button>
                </div>
              </>
            ) : (
              <>
                <div
                  className={`p-3 ${pathname == "/signup" ? "bg-[#36A9E0]" : ""}`}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <Link
                    to="signup"
                    className={`block font-semibold text-gray `}
                  >
                    Signup
                  </Link>
                </div>
                {/* <div
                  className={`p-3 ${pathname == '/news' ? 'bg-[#36A9E0]' : ''}`}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <Link to="news" className="block font-semibold text-gray">
                    News
                  </Link>
                </div>
                <div
                  className={`p-3 ${pathname == '/career' ? 'bg-[#36A9E0]' : ''}`}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <Link to="career" className="block font-semibold text-gray">
                    Careers
                  </Link>
                </div> */}
                <div
                  className={`p-3 ${pathname == "/download" ? "bg-[#36A9E0]" : ""}`}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <Link to="download" className="block font-semibold text-gray">
                    Downloads
                  </Link>
                </div>
                <div
                  className={`p-3 ${pathname == "/signin" ? "bg-[#36A9E0]" : ""}`}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <Link
                    to="signin"
                    className={`block font-semibold text-gray `}
                  >
                    Login
                  </Link>
                </div>
              </>
            )}
          </Drawer.Items>
        </Drawer>
      )}
      <Modal
        show={logoutConfirm}
        dismissible
        size={"md"}
        onClose={() => setLogoutConfirm(false)}
      >
        <Modal.Body className="space-y-4 font-nunito">
          <h6 className="text-[#0098DB] text-xl font-bold text-center">
            Are you sure you want to sign out?
          </h6>
        </Modal.Body>
        <Modal.Footer
          theme={{
            base: "flex justify-center py-3 border-0 space-x-3",
            popup: "border-0",
          }}
          className="font-nunito"
        >
          <button
            onClick={() => {
              setSignUser(undefined);
              navigate("/");
              setLogoutConfirm(false);
              setLogoutSuccess(true);
            }}
            className="bg-[#9B0E2A] rounded-md text-white px-8 py-2 font-bold text-lg disabled:opacity-45"
          >
            Yes
          </button>
          <button
            onClick={() => setLogoutConfirm(false)}
            className="bg-[#868899] rounded-md text-white px-8 py-2 font-bold text-lg disabled:opacity-45"
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={logoutSuccess}
        dismissible
        size={"md"}
        onClose={() => setLogoutSuccess(false)}
      >
        <Modal.Body className="space-y-4 font-nunito">
          <h6 className="text-[#0098DB] text-xl font-bold text-center">
            Successfully Logged Out!
          </h6>
        </Modal.Body>
        <Modal.Footer
          theme={{
            base: "flex justify-center py-3 border-0 space-x-3",
            popup: "border-0",
          }}
          className="font-nunito"
        >
          <button
            onClick={() => setLogoutSuccess(false)}
            className="bg-[#66B929] rounded-md text-white px-8 py-2 font-bold text-lg disabled:opacity-45"
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </header>
  );
};
