type IResultProps = {
  city: string;
  state: string;
  part_time: string;
  category: string;
  title: string;
  desc: string;
  sub_title: string;
};

const ResultItem = (props: IResultProps) => {
  const { city, state, part_time, category, title, desc, sub_title } = props;
  return (
    <div>
      <h6 className="text-[#0098DB] font-semibold md:text-xl xl:text-2xl">{title}</h6>
      <div className="text-[#3F3D56]">
        {city} {state}
      </div>
      <div className="text-[#3F3D56]">{part_time}</div>
      <div className="text-[#3F3D56]">{category}</div>
      <div className="text-[#3F3D56]">{sub_title}</div>
      <p className="text-[#3F3D56]">{desc}</p>
      <p className="text-end text-[#3F3D56]">Posted 30 days ago</p>
      <hr className="mt-5 " />
    </div>
  );
};
export const Career = () => {
  return (
    <div className="space-y-8 font-nunito">
      <section className="career_hero_bg w-full h-[475px] flex items-end justify-end max-md:hidden">
        <div className="flex font-nunito justify-end">
          <div className="news_start_bg max-xl:hidden text-white text-2xl font-bold h-[163px] flex items-center w-[173px] justify-center"></div>
          <div className="experience_bg max-xl:hidden text-white  font-bold h-[163px] w-[556px] flex items-center  ">
            <div className="ps-4 space-y-4">
              <div className="text-3xl">Serenaverse Careers</div>
              <div className="text-xl">Available Careers</div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <h5 className="text-center text-[#0098DB] text-2xl xl:text-3xl md:text-2xl">
          About Serenaverse Careers
        </h5>
        <div>
          <p className="text-[#3F3D56] xl:w-[60vw] md:w-[85vw] max-xl:text-center mx-auto font-light">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </div>
      </section>
      <section className="max-md:px-4">
        <div className="border border-[#D9D9D9] py-2 rounded-md flex xl:w-[70vw] md:w-[95vw] mx-auto px-4 justify-between">
          <div>
            <input
              type="text"
              className="py-2 w-[40vw] md:w-[25vw] border border-[#232429] rounded-sm"
              placeholder="Search"
            />
          </div>
          <div className="flex items-center space-x-8">
            <span className="text-[#232429] max-md:hidden"># jobs found</span>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              className="max-md:hidden"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 10.5V12.5H18V10.5H9H0ZM0 7.5H18V5.5H0V7.5ZM0 2.5H18V0.5H0V2.5ZM0 15.5V17.5H18V15.5H0Z"
                fill="#232429"
              />
            </svg>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              className="max-md:hidden"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 4.5H21V6.5H11V4.5ZM11 8.5H17V10.5H11V8.5ZM11 14.5H21V16.5H11V14.5ZM11 18.5H17V20.5H11V18.5ZM3 4.5H9V10.5H3V4.5ZM5 6.5V8.5H7V6.5H5ZM3 14.5H9V20.5H3V14.5ZM5 16.5V18.5H7V16.5H5Z"
                fill="#232429"
              />
            </svg>
            <div className="flex space-x-2">
              <span className="max-md:hidden">Sort</span>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_880_27098)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 16.708V11.5H15V16.637L13.914 15.551L12.5 16.966L16.036 20.5L19.571 16.965L18.157 15.55L17 16.708ZM12 10.5H4V12.5H12V10.5ZM18 7.5H4V9.5H18V7.5ZM20 4.5H4V6.5H20V4.5ZM10 13.5H4V15.5H10V13.5ZM8 16.5H4V18.5H8V16.5Z"
                    fill="#232429"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_880_27098">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(4 4.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                className="max-md:hidden"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 15L17 10H7L12 15Z" fill="#232429" />
              </svg>
            </div>
            <div className="flex space-x-2">
              <span className="max-md:hidden">Filter</span>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 3.5H4C3.447 3.5 3 3.947 3 4.5V7.09C3 7.613 3.213 8.127 3.583 8.497L9 13.914V21.5C9 21.847 9.18 22.168 9.475 22.351C9.635 22.45 9.817 22.5 10 22.5C10.153 22.5 10.306 22.465 10.447 22.395L14.447 20.395C14.786 20.225 15 19.879 15 19.5V13.914L20.417 8.497C20.787 8.127 21 7.613 21 7.09V4.5C21 3.947 20.553 3.5 20 3.5ZM13.293 12.793C13.105 12.98 13 13.234 13 13.5V18.882L11 19.882V13.5C11 13.234 10.895 12.98 10.707 12.793L5 7.09V5.5H19.001L19.003 7.083L13.293 12.793Z"
                  fill="#232429"
                />
              </svg>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                className="max-md:hidden"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 15L17 10H7L12 15Z" fill="#232429" />
              </svg>
            </div>
          </div>
        </div>
      </section>
      <section className="max-md:px-4">
        <div className="xl:w-[70vw] md:w-[95vw] mx-auto space-y-4">
          <ResultItem
            title="Example Career Title"
            city="city"
            state="state"
            part_time="Part time - $74,253.00 Annually"
            category="Category - Example"
            sub_title="Serenaverse"
            desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          />
          <ResultItem
            title="Example Career Title"
            city="city"
            state="state"
            part_time="Part time - $74,253.00 Annually"
            category="Category - Example"
            sub_title="Serenaverse"
            desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          />
          <ResultItem
            title="Example Career Title"
            city="city"
            state="state"
            part_time="Part time - $74,253.00 Annually"
            category="Category - Example"
            sub_title="Serenaverse"
            desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          />
          <ResultItem
            title="Example Career Title"
            city="city"
            state="state"
            part_time="Part time - $74,253.00 Annually"
            category="Category - Example"
            sub_title="Serenaverse"
            desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          />
          <ResultItem
            title="Example Career Title"
            city="city"
            state="state"
            part_time="Part time - $74,253.00 Annually"
            category="Category - Example"
            sub_title="Serenaverse"
            desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          />
          <ResultItem
            title="Example Career Title"
            city="city"
            state="state"
            part_time="Part time - $74,253.00 Annually"
            category="Category - Example"
            sub_title="Serenaverse"
            desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          />
          <ResultItem
            title="Example Career Title"
            city="city"
            state="state"
            part_time="Part time - $74,253.00 Annually"
            category="Category - Example"
            sub_title="Serenaverse"
            desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          />
        </div>
      </section>
      {/* <section className="py-4 font-nunito space-y-4">
        <div className="flex justify-center">
          <img src={oasis} alt="" />
        </div>
        <h6 className="font-bold text-[#0098DB] text-center text-xl">
          Our Support
        </h6>
        <h5 className="font-bold text-[#0098DB] text-center text-4xl">
          Administrator Support & Access
        </h5>
        <div className="flex justify-center">
          <p className="w-[1000px] text-lg font-light text-center">
            Therapists, doctors, mentors, and other professionals who tested
            OASIS viability, found the platform very effective and enjoyable for
            all participants. By scheduling private peer support sessions,
            professionals have the ability to invite patients to a one-on-one or
            group session in the VR world. Each VR session that is booked by a
            professional is private to ensure anonymity and closed off to other
            people in the world. Help our community of recovering addicts today.
          </p>
        </div>
      </section>
      <section className="font-nunito border border-y border-[#0098DB] py-5 mb-7">
        <div className="space-y-8">
          <h5 className=" font-bold text-4xl text-[#0098DB] text-center underline underline-offset-[15px] decoration-4">
            Send Us A Message
          </h5>
          <h6 className="text-[#0098DB] text-lg text-center">
            Send Us Any Questions Or Concerns
          </h6>
        </div>
        <div className="w-[644px] mx-auto grid grid-cols-2 gap-5 mt-5">
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="First Name"
            />
          </div>
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Last Name"
            />
          </div>
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Subject"
            />
          </div>
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Email Address"
            />
          </div>
          <div className=" col-span-2">
            <textarea
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Your Message"
              rows={5}
            ></textarea>
          </div>
          <div className="col-span-2">
            <button className="bg-[#0098DB] text-white text-lg w-full py-2 rounded-md">
              Contact
            </button>
          </div>
        </div>
      </section> */}
    </div>
  );
};
