import { Header, ILinkProps } from './Header';
import { Link, Outlet } from 'react-router-dom';
import { UseSynToken } from '../services/Query';
import { Footer } from './Footer';
export const LinkFooterItem = ({ title, link, selected }: ILinkProps) => {
  return (
    <div className="flex items-center justify-center">
      <Link
        className={`${
          selected === link ? 'font-bold  ' : ''
        } text-lg md:text-base`}
        to={link}
      >
        {title}
      </Link>
    </div>
  );
};
export const Layout = () => {
  const { data } = UseSynToken();
  return (
    <main>
      <Header />
      <div className="">
        <Outlet />
      </div>
      <Footer />
    </main>
  );
};
