import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  UseResetMemberPassword,
  UseSignupMemberInfo,
  UseUserTypes,
} from "../services/Query";
import lock_icon from "../assets/images/lock-privacy.svg";
import show_icon from "../assets/images/password_show.svg";
import hide_icon from "../assets/images/password_hide.svg";
import forget_icon from "../assets/images/forget_logo.png";
import TokenService from "../store/token";

import { useNavigate, useParams } from "react-router-dom";
import { Button, Modal, Spinner } from "flowbite-react";
import { AxiosError } from "axios";
type userFrm = {
  password: string;
  confirm_password: string;
};
export const ResetPassword = () => {
  const { mutate: saveMember } = UseSignupMemberInfo();
  const { token } = useParams();
  const navigate = useNavigate();
  const { data: privileges, isLoading: isPrivilegeData } = UseUserTypes();
  const [inValidNickname, setInValidNickname] = useState(false);
  const [inValidPassword, setinValidPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(0);
  const [resetSuccess, setResetSuccess] = useState(false);
  const {
    register,
    getValues,
    watch,
    formState: { isValid, errors },
  } = useForm<userFrm>({
    mode: "onChange",
  });
  useEffect(() => {
    if (token) TokenService.updateLocalAccessToken(token);
  }, [token]);
  const { mutate: resetPassword, isLoading: isUpdating } =
    UseResetMemberPassword();
  const updateUserPassword = () => {
    const data = getValues();
    resetPassword(JSON.stringify(data), {
      onSuccess: () => {
        setResetSuccess(true);
        // alert('Password updated successfully!');
      },
      onError: (error) => {
        const errors = error as AxiosError;
        setShowErrorMsg(true);
        if (errors.response?.status === 400) {
          setErrorMsg(1);
        } else if (errors.response?.status === 404) {
          setErrorMsg(2);
        }
      },
    });
  };
  return (
    <div className="flex items-center py-3 font-nunito md:md-h-vw-port">
      <div className="flex justify-center w-full py-4 space-x-10">
        <div className="w-[950px] space-y-8">
          <div className="flex justify-center">
            <img src={forget_icon} alt="" />
          </div>
          <h5 className="md:text-[40px] xl:text-5xl font-bold text-[#0098DB] text-center md:w-[75vw] md:mx-auto text-wrap xl:w-full">
            Please Enter New Password.
          </h5>
          <div className="max-md:w-[90vw] md:w-1/2 mx-auto space-y-5">
            <div className="bg-[#fff] py-3 flex px-4 shadow-lg">
              <div className="flex items-center">
                <img src={lock_icon} alt="" />
              </div>
              <div className="px-2 grow">
                <span className="text-[#232429] font-light block">
                  New Password
                </span>
                <input
                  type={showPassword ? "text" : "password"}
                  {...register("password", {
                    required: true,
                    validate: {
                      passwordValidate: (val: string) => {
                        const passwordRegex =
                          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,30}$/g;
                        if (!passwordRegex.test(val))
                          return "not matched rules";
                      },
                    },
                  })}
                  className="p-0 bg-transparent border-0 focus:ring-0"
                />
              </div>
              <div className="flex items-center">
                <img
                  src={showPassword ? hide_icon : show_icon}
                  alt=""
                  onClick={() => setShowPassword((prev) => !prev)}
                />
              </div>
            </div>
            {errors.password ? (
              <div className="text-base text-[#B00303]">
                The password. Length must be between 8-30 and contain at least
                one uppercase letter, at least one lowercase letter, at least
                one number and at least one symbol.
              </div>
            ) : null}
            <div className="bg-[#fff] py-3 flex px-4 shadow-lg ">
              <div className="flex items-center">
                <img src={lock_icon} alt="" />
              </div>
              <div className="px-2 grow">
                <span className="text-[#232429] font-light block">
                  Confirm New Password
                </span>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="p-0 bg-transparent border-0 focus:ring-0"
                  {...register("confirm_password", {
                    required: true,
                    validate: {
                      confirmPassword: (val: string) =>
                        val === watch("password") ||
                        "The passwords do not match",
                    },
                  })}
                />
              </div>
              <div className="flex items-center">
                <img
                  src={showConfirmPassword ? hide_icon : show_icon}
                  alt=""
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                />
              </div>
            </div>
            {errors.confirm_password ? (
              <div className="text-[#B00303]">
                confirm password does not match
              </div>
            ) : null}

            {/* <div className="text-lg text-[#B00303] text-center">
            This is an example error message for inputs
          </div> */}
            <div className="">
              <button
                className="w-full bg-[#0098DB] text-white font-bold text-lg py-3 rounded-md shadow-sm disabled:opacity-35"
                disabled={!isValid}
                onClick={updateUserPassword}
              >
                {isUpdating && (
                  <Spinner color="info" className="me-2" size={"sm"} />
                )}
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showErrorMsg} onClose={() => setShowErrorMsg(false)}>
        <Modal.Header
          theme={{
            base: "flex items-start justify-between rounded-t p-5 dark:border-gray-600",
          }}
        >
          Error
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6 text-lg">
            {errorMsg === 1 ? (
              <p>password cannot be modified more than twice a day.</p>
            ) : (
              <p>This email is a unregistered email.</p>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={resetSuccess}
        dismissible
        size={"md"}
        onClose={() => setResetSuccess(false)}
      >
        <Modal.Body className="space-y-4 font-nunito">
          <h6 className="text-[#0098DB] text-xl font-bold text-center">
            Reset your password successfully!
          </h6>
        </Modal.Body>
        <Modal.Footer
          theme={{
            base: "flex justify-center py-3 border-0",
            popup: "border-0",
          }}
          className="font-nunito"
        >
          <button
            onClick={() => {
              setResetSuccess(false);
              navigate("/signin");
            }}
            className="bg-[#66B929] rounded-md text-white px-8 py-2 font-bold text-lg disabled:opacity-45"
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
