import { UseSignupMemberInfo } from '../services/Query';
export const Agreement = () => {
  const { mutate: saveMember, isLoading: isSaving } = UseSignupMemberInfo();
  

  return (
    <div className="flex items-center py-3 font-nunito menu_h">
      <div className="flex justify-center w-full py-8">
        <div className="md:w-[80vw] xl:w-[860px] space-y-4 md:shadow-md">
          <div className="bg-[#66B929] text-white xl:text-3xl md:p-6 md:rounded-t-lg font-bold max-md:w-[80vw] max-md:py-2 max-md:px-3 max-md:rounded-md max-md:mx-auto">
            Serenaverse Service Agreement
          </div>
          <div className="px-6  space-y-2 text-[#232429]">
            <h5 className="font-semibold text-[#66B929] text-xl md:text-2xl">
              1. Services
            </h5>
            <p className='max-md:text-sm'>
              1.1 <b>Description of Services:</b> Provider shall offer virtual
              reality services enabling Doctors to meet their patients in
              private VR rooms.
            </p>
            <p className='max-md:text-sm'>
              1.2 <b>Scope of Services:</b> The services shall encompass the
              provision of access to virtual reality technology and private
              virtual meeting rooms for Doctors to communicate with and care for
              their patients.
            </p>
            <p className='max-md:text-sm'>
              1.3 <b>Patient Vetting:</b> Provider shall ensure the appropriate
              vetting of patients accessing the VR platform, ensuring the
              safety, privacy, and security of both parties.
            </p>
            <h5 className="font-semibold text-[#66B929] text-xl md:text-2xl">
              2. Responsibilities
            </h5>
            <h6>
              2.1 <b>Provider's Responsibilities:</b>
            </h6>
            <ul className="space-y-1 list-disc list-outside ps-4">
              <li className='max-md:text-sm'>
                Provide access to the virtual reality platform and private
                meeting rooms.
              </li>
              <li className='max-md:text-sm'>
                Ensure the platform's security and confidentiality measures for
                patient and Doctor interactions.
              </li>
              <li className='max-md:text-sm'>
                Maintain and manage the virtual reality infrastructure and
                technology.
              </li>
            </ul>
            <h6>
              2.2 <b>Doctor's Responsibilities:</b>
            </h6>
            <ul className="space-y-1 list-disc list-outside ps-4">
              <li className='max-md:text-sm'>
                Utilize the virtual reality platform in compliance with all
                applicable laws, regulations, and professional standards.
              </li>
              <li className='max-md:text-sm'>
                Ensure the accuracy and privacy of all patient-related
                information shared through the platform.
              </li>
              <li className='max-md:text-sm'>
                Abide by the terms and conditions outlined in this Agreement.
              </li>
            </ul>
            <h5 className="font-semibold text-[#66B929] text-xl md:text-2xl">
              3. Terms and Conditions
            </h5>
            <p className='max-md:text-sm'>
              3.1 <b>Duration of Agreement:</b> This Agreement shall commence on
              the Effective Date and continue until terminated by either party
              in accordance with the terms herein.
            </p>
            <p className='max-md:text-sm'>
              3.2 <b>Termination:</b> Either party may terminate this Agreement
              with written notice of 30 days.
            </p>
            <p className='max-md:text-sm'>
              3.3 <b>Confidentiality:</b> Both parties agree to maintain the
              confidentiality of any patient information and proprietary
              business information shared during the course of this Agreement.
            </p>
          </div>
          <div className='flex justify-end px-6 pb-4 space-x-3'>
            <button className='px-5 py-2 rounded-md text-[#232429] font-bold text-lg bg-[#D3D5D6]'>Decline</button>
            <button className='px-5 py-2 rounded-md bg-[#66B929] text-white text-lg font-bold'>Agree</button>
          </div>
        </div>
      </div>
    </div>
  );
};
