import headline_0 from '../assets/images/news_headline.png';
import headline_1 from '../assets/images/news_headline_1.png';
import headline_2 from '../assets/images/news_headline_2.png';
type ItemProp = {
  icon: string;
  title: string;
  sub_title: string;
  desc: string;
};
type HItemProps = {
  title: string;
  desc: string;
};
type catalogProps = {
  title: string;
};
const HeadlineItem = (props: ItemProp) => {
  const { title, desc, icon, sub_title } = props;
  return (
    <div className="bg-white rounded-lg xl:w-[680px] md:w-[45vw] p-5 mx-auto">
      <div className="">
        <div className="flex justify-center mb-3 md:justify-center">
          <img src={icon} alt="" className="xl:w-[678px]" />
        </div>
        <div className="flex">
          <div className="space-y-2 ">
            <h6 className="text-xl text-[#0098DB] xl:text-3xl">{title}</h6>
            <p className="text-base text-[#3F3D56] xl:text-xl">{sub_title}</p>
            <p className="text-base text-[#3F3D56] xl:text-lg font-light">
              {desc}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const HeadLineSItem = (props: HItemProps) => {
  const { title, desc } = props;
  return (
    <div className="xl:w-[730px] md:w-[45vw] flex space-x-4">
      <div>
        <div className="bg-[#D9D9D9] rounded-md w-[95px] h-[95px]"></div>
      </div>
      <div>
        <h6 className="text-[#0098DB] font-bold ">{title}</h6>
        <p className="text-[#3F3D56] font-light xl:text-lg">{desc}</p>
      </div>
    </div>
  );
};
const CatalogItem = (props: catalogProps) => {
  const { title } = props;
  return (
    <div className="xl:w-[730px] flex space-x-2 underline decoration-[#C9C9C9] underline-offset-8">
      <div className="flex items-center">
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5.5 5L0.5 0L0.5 10L5.5 5Z" fill="#9194B9" />
        </svg>
      </div>
      <div className="text-[#3F3D56]">{title}</div>
    </div>
  );
};
export const News = () => {
  return (
    <div className="space-y-8 font-nunito">
      <section className="news_hero_bg w-full h-[475px] flex items-end justify-end max-md:hidden">
        <div className="flex justify-end font-nunito">
          <div className="news_start_bg max-xl:hidden text-white text-2xl font-bold h-[163px] flex items-center w-[173px] justify-center"></div>
          <div className="experience_bg text-white  font-bold h-[163px] w-[556px] flex items-center max-xl:hidden ">
            <div className="space-y-4 ps-4">
              <div className="text-3xl">Serenaverse News</div>
              <div className="text-xl">Latest stories</div>
            </div>
          </div>
        </div>
      </section>
      <section className="grid pb-5 md:grid-cols-2 max-md:flex max-md:flex-col-reverse">
        <div>
          <HeadlineItem
            icon={headline_0}
            title="Headline"
            sub_title="Sub-headline Date"
            desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          />
          <HeadlineItem
            icon={headline_1}
            title="Headline"
            sub_title="Sub-headline Date"
            desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          />
          <HeadlineItem
            icon={headline_2}
            title="Headline"
            sub_title="Sub-headline Date"
            desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          />
        </div>
        <div className="space-y-4 max-md:px-4">
          <div className="bg-[#C3E5F6] xl:w-[730px] p-5 rounded-md space-y-2">
            <h6 className="text-[#3F3D56]">Search</h6>
            <div>
              <input
                type="text"
                className="w-full border-0 rounded-md"
                placeholder="Enter Your Keyword"
              />
            </div>
          </div>
          <div className="pb-5">
            <h6 className="text-[#0098DB] xl:text-xl underline underline-offset-[14px] decoration-[#66B929]">
              Popular Keywords
            </h6>
          </div>
          <div className="flex flex-wrap xl:w-[730px] ">
            <span className="text-[#232429] py-2 px-4 bg-[#F2F2F2] border border-[#C3C3C3] rounded-md me-4 mb-4">
              Keyword
            </span>
            <span className="text-[#232429] py-2 px-4 bg-[#F2F2F2] border border-[#C3C3C3] rounded-md me-4 mb-4">
              Keyword
            </span>
            <span className="text-[#232429] py-2 px-4 bg-[#F2F2F2] border border-[#C3C3C3] rounded-md me-4 mb-4">
              Keyword
            </span>
            <span className="text-[#232429] py-2 px-4 bg-[#F2F2F2] border border-[#C3C3C3] rounded-md me-4 mb-4">
              Keyword
            </span>
            <span className="text-[#232429] py-2 px-4 bg-[#F2F2F2] border border-[#C3C3C3] rounded-md me-4 mb-4">
              Keyword
            </span>
            <span className="text-[#232429] py-2 px-4 bg-[#F2F2F2] border border-[#C3C3C3] rounded-md me-4 mb-4">
              Keyword
            </span>
            <span className="text-[#232429] py-2 px-4 bg-[#F2F2F2] border border-[#C3C3C3] rounded-md me-4 mb-4">
              Keyword
            </span>
            <span className="text-[#232429] py-2 px-4 bg-[#F2F2F2] border border-[#C3C3C3] rounded-md me-4 mb-4">
              Keyword
            </span>
            <span className="text-[#232429] py-2 px-4 bg-[#F2F2F2] border border-[#C3C3C3] rounded-md me-4 mb-4">
              Keyword
            </span>
            <span className="text-[#232429] py-2 px-4 bg-[#F2F2F2] border border-[#C3C3C3] rounded-md me-4 mb-4">
              Keyword
            </span>
          </div>
          <div className="pb-5">
            <h6 className="text-[#0098DB] xl:text-xl underline underline-offset-[14px] decoration-[#66B929]">
              Recent News
            </h6>
          </div>
           <div className="space-y-4">
            <HeadLineSItem
              title="Headline"
              desc={
                'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
              }
            />
            <HeadLineSItem
              title="Headline"
              desc={
                'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
              }
            />
            <HeadLineSItem
              title="Headline"
              desc={
                'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
              }
            />
            <HeadLineSItem
              title="Headline"
              desc={
                'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
              }
            />
            <HeadLineSItem
              title="Headline"
              desc={
                'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
              }
            />
            <HeadLineSItem
              title="Headline"
              desc={
                'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
              }
            />
            <HeadLineSItem
              title="Headline"
              desc={
                'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
              }
            />
          </div>
          <div className="pb-5">
            <h6 className="text-[#0098DB] xl:text-xl underline underline-offset-[14px] decoration-[#66B929]">
              Categories
            </h6>
          </div>
          <div className="grid gap-y-4">
            <CatalogItem title="Proident adipiscing elit" />
            <CatalogItem title="Proident adipiscing elit" />
            <CatalogItem title="Proident adipiscing elit" />
            <CatalogItem title="Proident adipiscing elit" />
            <CatalogItem title="Proident adipiscing elit" />
            <CatalogItem title="Proident adipiscing elit" />
          </div> {/**/}
        </div>
      </section>
      {/* <section className="py-4 space-y-4 font-nunito">
        <div className="flex justify-center">
          <img src={oasis} alt="" />
        </div>
        <h6 className="font-bold text-[#0098DB] text-center text-xl">
          Our Support
        </h6>
        <h5 className="font-bold text-[#0098DB] text-center text-4xl">
          Administrator Support & Access
        </h5>
        <div className="flex justify-center">
          <p className="w-[1000px] text-lg font-light text-center">
            Therapists, doctors, mentors, and other professionals who tested
            OASIS viability, found the platform very effective and enjoyable for
            all participants. By scheduling private peer support sessions,
            professionals have the ability to invite patients to a one-on-one or
            group session in the VR world. Each VR session that is booked by a
            professional is private to ensure anonymity and closed off to other
            people in the world. Help our community of recovering addicts today.
          </p>
        </div>
      </section>
      <section className="font-nunito border border-y border-[#0098DB] py-5 mb-7">
        <div className="space-y-8">
          <h5 className=" font-bold text-4xl text-[#0098DB] text-center underline underline-offset-[15px] decoration-4">
            Send Us A Message
          </h5>
          <h6 className="text-[#0098DB] text-lg text-center">
            Send Us Any Questions Or Concerns
          </h6>
        </div>
        <div className="w-[644px] mx-auto grid grid-cols-2 gap-5 mt-5">
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="First Name"
            />
          </div>
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Last Name"
            />
          </div>
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Subject"
            />
          </div>
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Email Address"
            />
          </div>
          <div className="col-span-2 ">
            <textarea
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Your Message"
              rows={5}
            ></textarea>
          </div>
          <div className="col-span-2">
            <button className="bg-[#0098DB] text-white text-lg w-full py-2 rounded-md">
              Contact
            </button>
          </div>
        </div>
      </section> */}
    </div>
  );
};
