import {
  Button,
  Card,
  Label,
  Modal,
  Select,
  Spinner,
  TextInput,
} from "flowbite-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  UseResetMemberNickName,
  UseSignupMemberInfo,
  UseUserProfile,
  UseUserTypes,
} from "../services/Query";
import warn_icon from "../assets/images/warning_icon.svg";
import edit_icon from "../assets/images/edit_icon.svg";
import { signUser } from "../store";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { deleteAccount } from "../services/HttpServices";
type userFrm = {
  id: string;
  avatar_id: string;
  firstName: string;
  lastName: string;
  email: string;
  confirm_email: string;
  password: string;
  confirm_password: string;
  nickname: string;
  avatar_url: string;
  privilege_id: string | null;
  usersetting_id: string | null;
  worldVolume: number | null;
  voiceVolume: number | null;
  mic: number | null;
  streetNumber: string | null;
  streetName: string | null;
  town: string | null;
  stateProvince: string | null;
  country: string | null;
  height: number | null;
  eligibleForNameChange: boolean;
  highEduLevel: string;
  martialStatus: string;
  gender: string;
};
const nickNameValidate = (nickName: string): boolean => {
  let bSuccess = true;
  let upperCaseLetters = /[^a-zA-Z0-9(.|\-|@|_)]/g;
  if (upperCaseLetters.test(nickName)) {
    bSuccess = false;
  }
  return bSuccess;
};
const passwordValidate = (password: string): boolean => {
  let bSuccess = true;

  let Letters = /[A-Z]/g;
  if (!Letters.test(password)) {
    bSuccess = false;
  }
  Letters = /[a-z]/g;
  if (!Letters.test(password)) {
    bSuccess = false;
  }
  Letters = /[0-9]/g;
  if (!Letters.test(password)) {
    bSuccess = false;
  }
  const format = /\W|_/g;
  if (!format.test(password)) {
    bSuccess = false;
  }
  return bSuccess;
};
export const stateList = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Guam",
    abbreviation: "GU",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Palau",
    abbreviation: "PW",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
];
const educationLevel = [
  { name: "High School Diploma/GED", abbrev: "HSD" },
  { name: "Some College/Associate's Degree", abbrev: "SC/A D" },
  { name: "Bachelor's Degree", abbrev: "B.D" },
  { name: "Master's Degree", abbrev: "M.D" },
  { name: "Doctorate/Ph.D.", abbrev: "D.D" },
  { name: "Professional Degree (e.g., MD, JD, DDS)", abbrev: "P.D" },
  { name: "Vocational/Technical Training", abbrev: "V/T.T" },
];
const MartialKind = ["Married", "Single", "Divorced", "Widowed"];
const genderKind = [
  "Male",
  "Female",
  "Non-binary",
  "Genderqueer",
  "Genderfluid",
  "Agender",
  "Transgender",
  "Prefer not to say",
];
export const ProfileDs = () => {
  const { mutate: saveMember, isLoading: isSaving } = UseUserProfile();
  const { mutate: updateMemberNickname, isLoading: isUpdatingNickName } =
    UseResetMemberNickName();
  const navigate = useNavigate();
  const { data: privileges, isLoading: isPrivilegeData } = UseUserTypes();
  const [inValidNickname, setInValidNickname] = useState(false);
  const [inValidPassword, setinValidPassword] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [showError, setshowError] = useState(false);
  const [sUser, setSignUser] = useRecoilState(signUser);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const {
    register,
    getValues,
    setValue,
    reset,
    watch,
    formState: { isValid, dirtyFields, errors },
  } = useForm<userFrm>({
    mode: "onChange",
  });
  const [isList, setIsList] = useState(true);
  const [errMessage, setErrMessage] = useState("");

  const { nickname, password, id } = watch();
  useEffect(() => {
    setInValidNickname(
      !nickNameValidate(nickname) && (dirtyFields.nickname || false)
    );
  }, [nickname]);
  useEffect(() => {
    if (sUser) {
      reset({
        id: sUser.id,
        email: sUser.email,
        nickname: sUser.nickName,
        firstName: sUser.firstname || "",
        lastName: sUser.lastName || "",
        stateProvince: sUser.user_state,
        highEduLevel: sUser.highEduLevel || "",
        martialStatus: sUser.martialStatus || "",
        gender: sUser.gender || "",
      });
    } else {
      navigate("/signin?redirect=profile");
    }
  }, [sUser]);
  // console.log('sUser', sUser)
  useEffect(() => {
    setinValidPassword(
      !passwordValidate(password) && (dirtyFields?.password || false)
    );
  }, [password]);
  const saveUser = () => {
    const data = getValues();
    // if (!nickNameValidate(nickname)) {
    //   alert("wrong nickname. please check nickname.!");
    //   return;
    // }
    // if (!passwordValidate(password)) {
    //   alert("wrong nickname. please check nickname.!");
    //   return;
    // }
    saveMember(JSON.stringify(data), {
      onSuccess: (res) => {
        setSignUser(res);
        setIsEdit(false);
      },
      onError: () => {
        // alert('this signup is failed!');
      },
    });
  };
  return (
    <div className="flex items-center py-3 md:md-h-vw-port xl:xl-h-vw-port font-nunito">
      <div className="flex justify-center w-full py-8 md:space-x-10">
        <div className="max-md:w-[100vw] md:w-[90vw] xl:w-[860px] space-y-8">
          <h5 className="text-5xl font-bold text-[#0098DB] text-center">
            Profile Page
          </h5>
          <div className="md:border border-[#ADADAD] rounded-lg p-8 space-y-5">
            <h6 className="text-[#0098DB] text-xl flex space-x-1">
              <span className="font-bold">Basic information</span>
              <div className="flex items-center">
                {/* <img src={edit_icon} alt="" /> */}
              </div>
            </h6>
            <div className="border border-x-0 border-t-0 border-[#ADADAD] py-2 flex max-md:justify-between">
              <div className=" font-semibold text-[#232429] w-1/4">
                Nickname
                <div
                  className={`font-lighter text-[#0098DB] w-full ${
                    errors.nickname && "text-[#9B0E2A]"
                  }`}
                >
                  6 to 12 characters
                </div>
              </div>
              <div className="flex grow max-md:justify-end">
                <div>
                  <input
                    type="text"
                    {...register("nickname", {
                      required: true,
                      validate: (val: string) => {
                        const upperCaseLetters = /[^a-zA-Z0-9(.|\-|@|_)]/g;
                        if (val.length < 6 || val.length > 12)
                          return "invalidate length.";
                        if (upperCaseLetters.test(val))
                          return "invalidate pattern.";
                      },
                    })}
                    className="py-0 max-md:text-end border border-[#ADADAD] rounded"
                  />
                </div>
              </div>
              <div>
                <button
                  disabled={
                    !dirtyFields.nickname || errors.nickname != undefined
                  }
                  className="text-[#0098DB] font-semibold disabled:opacity-40"
                  onClick={() => {
                    updateMemberNickname(
                      JSON.stringify({
                        displayName: nickname,
                      }),
                      {
                        onSuccess: (res) => {
                          setSignUser(res);
                        },
                        onError: (err) => {
                          const errRep = err as AxiosError;
                          const errMsg = errRep.response?.data as {
                            errMsg: string;
                          };
                          setErrMessage(errMsg?.errMsg);
                          setshowError(true);
                          setValue("nickname", sUser?.nickName || "");
                        },
                      }
                    );
                  }}
                >
                  {isUpdatingNickName ? (
                    <Spinner color="info" aria-label="Info spinner example" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
            <div></div>
            <div className="border border-x-0 border-t-0 border-[#ADADAD] py-2 flex max-md:justify-between">
              <div className=" font-semibold text-[#232429] w-1/4">Email</div>
              <div className="flex grow max-md:justify-end">
                <div>
                  <input
                    type="text"
                    disabled={true}
                    {...register("email")}
                    className="py-0 border-0 max-md:text-end"
                  />
                </div>
              </div>
            </div>
            <div className="border border-x-0 border-t-0 border-[#ADADAD] py-2 flex max-md:justify-between">
              <div className=" font-semibold text-[#232429] w-1/4">
                Password
              </div>
              <div className="flex grow max-md:justify-end">
                <div>
                  <input
                    type="password"
                    disabled={true}
                    value={"****************"}
                    {...register("password")}
                    className="py-0 border-0 max-md:text-end"
                  />
                </div>
              </div>
              <div>
                <button
                  className="text-[#0098DB] font-semibold"
                  onClick={() => {
                    navigate("/update_password");
                  }}
                >
                  Change password
                </button>
              </div>
            </div>
            <div>
              <button
                className="bg-[#9B0E2A] text-white font-bold py-2 px-6 rounded-lg mx-auto block"
                onClick={() => {
                  setDeleteConfirm(true);
                }}
              >
                Delete Account
              </button>
            </div>
          </div>
          <div className="md:border border-[#ADADAD] rounded-lg p-8 space-y-5">
            <h6 className="text-[#0098DB] text-xl flex space-x-1">
              <span className="font-bold">Optional Information</span>
              <div className="flex items-center">
                {!isEdit && (
                  <img
                    src={edit_icon}
                    alt=""
                    className="cursor-pointer"
                    onClick={() => {
                      setIsEdit(true);
                    }}
                  />
                )}
              </div>
            </h6>
            <div className="border border-x-0 border-t-0 border-[#ADADAD] py-2 flex max-md:justify-between">
              <div className=" font-semibold text-[#232429] w-1/4">
                First name
              </div>
              <div className="flex grow max-md:justify-end">
                <div>
                  <input
                    type="text"
                    disabled={!isEdit}
                    {...register("firstName")}
                    className="py-0 max-md:text-end border border-[#ADADAD] rounded"
                  />
                </div>
              </div>
            </div>
            <div className="border border-x-0 border-t-0 border-[#ADADAD] py-2 flex max-md:justify-between">
              <div className=" font-semibold text-[#232429] w-1/4">
                Last name
              </div>
              <div className="flex grow max-md:justify-end">
                <div>
                  <input
                    type="text"
                    disabled={!isEdit}
                    {...register("lastName")}
                    className="py-0 max-md:text-end border border-[#ADADAD] rounded"
                  />
                </div>
              </div>
            </div>
            <div className="border border-x-0 border-t-0 border-[#ADADAD] py-2 flex max-md:justify-between">
              <div className=" font-semibold text-[#232429] md:w-1/4 max-md:w-[55vw] flex items-center">
                State (residency)
              </div>
              <div className="flex justify-end md:hidden">
                <div>
                  <select
                    className="border border-[#ADADAD] rounded custom_combo ring-0 px-3 text-[#232429]"
                    disabled={!isEdit}
                    {...register("stateProvince")}
                  >
                    <option value=""></option>
                    {stateList.map((it, idx) => (
                      <option value={it.name} key={it.abbreviation}>
                        {it.abbreviation}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="md:flex md:grow max-md:hidden">
                <div>
                  <select
                    className="border border-[#ADADAD] rounded custom_combo ring-0 px-3 text-[#232429]"
                    disabled={!isEdit}
                    {...register("stateProvince")}
                  >
                    <option value=""></option>
                    {stateList.map((it, idx) => (
                      <option value={it.name} key={it.abbreviation}>
                        {it.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="border border-x-0 border-t-0 border-[#ADADAD] py-2 flex max-md:justify-between">
              <div className=" font-semibold text-[#232429] md:w-1/4 items-center">
                Highest Educational Level 
              </div>
              <div className="flex justify-end md:hidden grow">
                <select
                  {...register("highEduLevel")}
                  disabled={!isEdit}
                  className="border border-[#ADADAD] rounded custom_combo ring-0 px-3 text-[#232429]"
                >
                  <option value=""></option>
                  {educationLevel.map((it, idx) => (
                    <option value={it.name} key={it.abbrev}>
                      {it.abbrev}
                    </option>
                  ))}
                </select>
              </div>
              <div className="max-md:hidden grow">
                <select
                  {...register("highEduLevel")}
                  disabled={!isEdit}
                  className="border border-[#ADADAD] rounded custom_combo ring-0 px-3 text-[#232429]"
                >
                  <option value=""></option>
                  {educationLevel.map((it, idx) => (
                    <option value={it.name} key={it.name}>
                      {it.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="border border-x-0 border-t-0 border-[#ADADAD] py-2 flex max-md:justify-between">
              <div className=" font-semibold text-[#232429] w-1/4">Gender</div>
              <div className="flex grow max-md:justify-end">
                <div>
                  <select
                    {...register("gender")}
                    disabled={!isEdit}
                    className="border border-[#ADADAD] rounded custom_combo ring-0 px-3 text-[#232429]"
                  >
                    <option value=""></option>
                    {genderKind.map((it, idx) => (
                      <option value={it} key={it}>
                        {it}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="border border-x-0 border-t-0 border-[#ADADAD] py-2 flex max-md:justify-between">
              <div className=" font-semibold text-[#232429] w-1/4">
                Martial Status
              </div>
              <div className="flex grow max-md:justify-end">
                <select
                  {...register("martialStatus")}
                  disabled={!isEdit}
                  className="border border-[#ADADAD] rounded custom_combo ring-0 px-3 text-[#232429]"
                >
                  <option value=""></option>
                  {MartialKind.map((it, idx) => (
                    <option value={it} key={it}>
                      {it}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex justify-end space-x-4">
              {isEdit && (
                <>
                  <button
                    className="bg-[#0098DB] text-white font-bold py-2 px-6 rounded-lg"
                    onClick={() => {
                      saveUser();
                    }}
                  >
                    {isSaving && (
                      <Spinner color="info" className="me-2" size={"sm"} />
                    )}
                    Save
                  </button>
                  <button
                    className="bg-[#9B0E2A] text-white font-bold py-2 px-6 rounded-lg"
                    onClick={() => {
                      setIsEdit(false);
                    }}
                  >
                    Close
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={deleteConfirm} onClose={() => setDeleteConfirm(false)}>
        <Modal.Header
          theme={{
            base: "flex justify-center rounded-t w-full p-5 dark:border-gray-600 font-nunito",
          }}
        ></Modal.Header>
        <Modal.Body
          theme={{
            base: "flex-1 overflow-auto p-6 font-nunito",
          }}
        >
          <div className="text-[#9B0E2A] text-center text-xl font-bold mb-4">
            Are you sure you want to remove?
          </div>
          {/* <div className="space-y-6">
            <p className="text-[#232429] text-center text-xl font-bold">
              This action is not reversible
            </p>
          </div> */}
        </Modal.Body>
        <Modal.Footer
          theme={{
            base: "flex items-center justify-center py-2 space-x-8 font-nunito mb-5",
            popup: "border-0",
          }}
        >
          <button
            className="bg-[#868899] text-white font-semibold px-8 py-3 rounded-md"
            onClick={() => setDeleteConfirm(false)}
          >
            No
          </button>
          <button
            className="px-8 py-3 rounded-md bg-[#9B0E2A] font-semibold text-white"
            type="button"
            onClick={async () => {
              if (sUser) {
                try {
                  setIsDeleting(true);
                  await deleteAccount(sUser.id);
                  setIsDeleting(false);
                  setSignUser(undefined);
                } catch (err) {
                  setIsDeleting(false);
                }
              }
            }}
          >
            {isDeleting && (
              <Spinner color="info" className="me-2" size={"sm"} />
            )}
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showError} onClose={() => setshowError(false)}>
        <Modal.Header
          theme={{
            base: "flex justify-center rounded-t w-full p-5 dark:border-gray-600 font-nunito",
          }}
        ></Modal.Header>
        <Modal.Body
          theme={{
            base: "flex-1 overflow-auto p-6 font-nunito",
          }}
        >
          <div className="flex items-center justify-center space-x-2 mb-3 mt-3">
            <img src={warn_icon} alt="" />
            <span className="font-bold text-[#9B0E2A] text-2xl">Warning</span>
          </div>
          <p className="text-center  text-[#0098DB] text-xl">{errMessage}</p>
        </Modal.Body>
        <Modal.Footer
          theme={{
            base: "flex items-center justify-center py-2 space-x-8 font-nunito mb-5",
            popup: "border-0",
          }}
        >
          <button
            className="px-6 py-2 rounded-md bg-[#9B0E2A] font-semibold text-white"
            onClick={() => setshowError(false)}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
