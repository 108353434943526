import unselected_circle from '../assets/images/unselected_circle_icon.svg';
import unselected_arrow from '../assets/images/unselected_arrow_icon.svg';
import selected_circle from '../assets/images/selected_circle_icon.svg';
import selected_arrow from '../assets/images/selected_arrow_icon.svg';
import { useState } from 'react';

type IFaqProps = {
  title: string;
  desc: string;
};
export const FAQ = () => {
  const [selected, setSelected] = useState(0);
  const dataList: IFaqProps[] = [
    {
      title: 'Nulla eget nisi sed libero molestie feugia?',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget nisi sed libero molestie feugiat. Proin lacinia efficitur felis, eu ultrices sapien sagittis nec.',
    },
    {
      title: 'Nulla eget nisi sed libero molestie feugia?',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget nisi sed libero molestie feugiat. Proin lacinia efficitur felis, eu ultrices sapien sagittis nec.',
    },
    {
      title:
        'Proin lacinia efficitur felis, eu ultrices sapien sagittis nec. Proin lacinia efficitur felis, eu ultrices sapien sagittis nec.',
      desc: 'Proin lacinia efficitur felis, eu ultrices sapien sagittis nec. Proin lacinia efficitur felis, eu ultrices sapien sagittis nec. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget nisi sed libero molestie feugiat. Proin lacinia efficitur felis, eu ultrices sapien sagittis nec.',
    },
    {
      title: 'Nulla eget nisi sed libero molestie feugia?',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget nisi sed libero molestie feugiat. Proin lacinia efficitur felis, eu ultrices sapien sagittis nec.',
    },
    {
      title:
        'Proin lacinia efficitur felis, eu ultrices sapien sagittis nec. Proin lacinia efficitur felis, eu ultrices sapien sagittis nec.',
      desc: 'Proin lacinia efficitur felis, eu ultrices sapien sagittis nec. Proin lacinia efficitur felis, eu ultrices sapien sagittis nec. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget nisi sed libero molestie feugiat. Proin lacinia efficitur felis, eu ultrices sapien sagittis nec.',
    },
  ];
  return (
    <div className="pb-10 space-y-8 font-nunito">
      <section className="landing_hero_bg w-full h-[475px] flex items-end justify-end max-md:hidden">
        <div className="flex justify-end font-nunito">
          {/* <div className="start_nw text-white text-2xl font-bold h-[163px] flex items-center w-[173px] justify-center">
            Start Now
          </div>
          <div className="experience_bg text-white text-2xl font-bold h-[163px] w-[556px] flex items-center ">
            <div className="ps-4">
              <div>Serenaverse</div>
              <div>Experience Immersive Addiction Support</div>
            </div>
          </div> */}
        </div>
      </section>

      <section className="max-md:px-3">
        <div className="xl:w-[80vw] md:w-[98vw] mx-auto xl:space-y-4 max-md:hidden">
          <h5 className="text-[#18191F] xl:text-5xl font-extrabold">
            Frequently Asked Questions
          </h5>
          <div className="relative flex min-h-[470px] items-center">
            <div className="faq_desc md:w-[48vw] xl:w-[40vw] min-h-[470px] -z-10 right-0 top-0 absolute md:p-4 xl:p-8 xl:space-y-4">
              {selected > -1 && (
                <>
                  <h6 className="text-[#18191F] font-bold text-xl">
                    {dataList[selected].title}
                  </h6>
                  <p className="text-[#18191F]">{dataList[selected].desc}</p>
                </>
              )}
            </div>
            <div className="md:w-[49vw] xl:w-[41vw] z-0">
              {dataList.map((it, idx) => (
                <div
                  className={`md:min-h-[50px] md:py-3 xl:h-[72px] xl:flex xl:items-center shadow-sm cursor-pointer ${
                    selected === idx ? 'bg-[#FAFBFF]' : 'bg-white'
                  } `}
                >
                  <div
                    className="flex w-full px-4 xl:space-x-2 md:space-x-1"
                    key={idx}
                    onClick={() => {
                      setSelected(idx);
                    }}
                  >
                    <div className="flex-none">
                      <img
                        src={
                          selected === idx ? selected_circle : unselected_circle
                        }
                        alt=""
                      />
                    </div>
                    <div className="grow">
                      <h6>{it.title}</h6>
                    </div>
                    <div className="flex items-center flex-none">
                      <img
                        src={
                          selected === idx ? selected_arrow : unselected_arrow
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="md:hidden">
          <h5 className="text-[#18191F] text-xl font-extrabold">
            Frequently Asked Questions
          </h5>
          <div className="flex items-center">
            <div className="">
              {dataList.map((it, idx) => (
                <>
                  <div
                    className={`min-h-[50px] py-3 xl:flex xl:items-center shadow-sm cursor-pointer ${
                      selected === idx ? 'bg-[#FAFBFF]' : 'bg-white'
                    } `}
                  >
                    <div
                      className="flex w-full px-4 space-x-1"
                      key={idx}
                      onClick={() => {
                        setSelected(idx);
                      }}
                    >
                      <div className="flex-none">
                        <img
                          src={
                            selected === idx
                              ? selected_circle
                              : unselected_circle
                          }
                          alt=""
                        />
                      </div>
                      <div className="grow">
                        <h6>{it.title}</h6>
                      </div>
                      <div className="flex items-center flex-none">
                        <img
                          src={
                            selected === idx ? selected_arrow : unselected_arrow
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  {selected === idx && (
                    <div className="faq_desc p-4 space-y-3">
                      {selected > -1 && (
                        <>
                          <h6 className="text-[#18191F] font-bold text-lg">
                            {dataList[selected].title}
                          </h6>
                          <p className="text-[#18191F]">
                            {dataList[selected].desc}
                          </p>
                        </>
                      )}
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* <section className="py-4 space-y-4 font-nunito">
        <div className="flex justify-center">
          <img src={oasis} alt="" />
        </div>
        <h6 className="font-bold text-[#0098DB] text-center text-xl">
          Our Support
        </h6>
        <h5 className="font-bold text-[#0098DB] text-center text-4xl">
          Administrator Support & Access
        </h5>
        <div className="flex justify-center">
          <p className="w-[1000px] text-lg font-light text-center">
            Therapists, doctors, mentors, and other professionals who tested
            OASIS viability, found the platform very effective and enjoyable for
            all participants. By scheduling private peer support sessions,
            professionals have the ability to invite patients to a one-on-one or
            group session in the VR world. Each VR session that is booked by a
            professional is private to ensure anonymity and closed off to other
            people in the world. Help our community of recovering addicts today.
          </p>
        </div>
      </section>
      <section className="font-nunito border border-y border-[#0098DB] py-5 mb-7">
        <div className="space-y-8">
          <h5 className=" font-bold text-4xl text-[#0098DB] text-center underline underline-offset-[15px] decoration-4">
            Send Us A Message
          </h5>
          <h6 className="text-[#0098DB] text-lg text-center">
            Send Us Any Questions Or Concerns
          </h6>
        </div>
        <div className="w-[644px] mx-auto grid grid-cols-2 gap-5 mt-5">
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="First Name"
            />
          </div>
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Last Name"
            />
          </div>
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Subject"
            />
          </div>
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Email Address"
            />
          </div>
          <div className="col-span-2 ">
            <textarea
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Your Message"
              rows={5}
            ></textarea>
          </div>
          <div className="col-span-2">
            <button className="bg-[#0098DB] text-white text-lg w-full py-2 rounded-md">
              Contact
            </button>
          </div>
        </div>
      </section> */}
    </div>
  );
};
