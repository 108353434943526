class TokenService {
  getLocalAccessToken() {
    const token = localStorage.getItem('token');
    return token;
  }

  updateLocalAccessToken(token: string) {
    localStorage.setItem('token', JSON.stringify(token));
  }
}
export default new TokenService();
