import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { Member } from '../models/Member';

const { persistAtom } = recoilPersist();

export const signUser = atom<Member | undefined>({
  key: 'signed_user',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
