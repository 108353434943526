import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  UseSendForgetPasswordEmail,
  UseSignupMemberInfo,
  UseUserTypes,
} from "../services/Query";
import mail_icon from "../assets/images/mail_icon.svg";
import success_icon from "../assets/images/success_icon.svg";
import forget_icon from "../assets/images/forget_logo.png";
import { Modal, Spinner } from "flowbite-react";
type signFrm = {
  email: string;
  password: string;
};

export const ForgetPassword = () => {
  const navigate = useNavigate();
  const { mutate: saveMember, isLoading: isSaving } = UseSignupMemberInfo();
  const {
    register,
    getValues,
    watch,
    formState: { isValid, dirtyFields, errors },
  } = useForm<signFrm>({
    mode: "onChange",
  });
  const { mutate: sendForgetPasswordEmail, isLoading: isSending } =
    UseSendForgetPasswordEmail();
  const onSendForgetPasswordEmail = (email: string) => {
    sendForgetPasswordEmail(email, {
      onSuccess: () => {
        setShowConfirmPassword(true);
      },
      onError: () => {
        setShowConfirmPassword(true);
      },
    });
  };
  const { data: privileges, isLoading: isPrivilegeData } = UseUserTypes();
  const [inValidNickname, setInValidNickname] = useState(false);
  const [inValidPassword, setinValidPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <div className="flex items-center py-3 font-nunito md:md-h-vw-port xl:xl-h-vw-port">
      <div className="flex justify-center w-full py-5">
        <div className="w-[860px] space-y-4">
          <div className="flex justify-center">
            <img src={forget_icon} alt="" />
          </div>
          <div>
            <h5 className="text-[40px] font-bold text-[#0098DB] text-center md:w-[80vw] md:mx-auto xl:w-full">
              Please enter your email address to reset password
            </h5>
            <h6 className="text-center">
              Please enter the email address associated with your Serenaverse
              account.
            </h6>
          </div>
          <div className="w-1/2 mx-auto space-y-5">
            <div className="bg-[#fff] py-3 flex px-4 shadow-lg">
              <div className="flex items-center">
                <img src={mail_icon} alt="" />
              </div>
              <div className="px-2 grow">
                <span className="text-[#232429] font-light block">
                  Email Address (username)
                </span>
                <input
                  type="email"
                  className="p-0 bg-transparent border-0 focus:ring-0"
                  {...register("email", {
                    required: true,
                    validate: (val: string) => {
                      const regex =
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gi;
                      if (!regex.test(val)) {
                        return "wrong email";
                      }
                    },
                  })}
                />
              </div>
              <div className="flex items-center">
                {isValid ? (
                  <img
                    src={success_icon}
                    alt=""
                    onClick={() => setShowPassword((prev) => !prev)}
                  />
                ) : null}
              </div>
            </div>

            <div className="">
              <button
                className="w-full bg-[#66B929] text-white font-bold text-lg py-3 rounded-md shadow-sm disabled:opacity-35"
                disabled={!isValid}
                onClick={() => {
                  onSendForgetPasswordEmail(getValues("email"));
                }}
              >
                {isSending && (
                  <Spinner color="info" className="me-2" size={"sm"} />
                )}
                Send Password Recovery To Email
              </button>
            </div>
            <h6 className="text-center text-xl text-[#646464]">
              Didn’t receive password recovery email?
            </h6>
            <div className="">
              <button
                className="w-full bg-white text-[#0098DB] font-bold text-lg py-3 rounded-md shadow-sm border border-[#0098DB] disabled:opacity-35"
                disabled={!isValid}
              >
                Resend Email
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showConfirmPassword}
        dismissible
        size={"md"}
        onClose={() => setShowConfirmPassword(false)}
      >
        <Modal.Body className="space-y-4 font-nunito">
          <h6 className="text-[#0098DB] text-xl font-bold text-center">
            Successfully requested!
          </h6>
          <p className="font-normal text-center">
            Please check your mailbox. <br />
            It may take 5 to 12 minutes.
          </p>
        </Modal.Body>
        <Modal.Footer
          theme={{
            base: "flex justify-center py-3 border-0",
            popup: "border-0",
          }}
          className="font-nunito"
        >
          <button
            onClick={() => {
              setShowConfirmPassword(false);
              navigate("/");
            }}
            className="bg-[#66B929] rounded-md text-white px-8 py-2 font-bold text-lg disabled:opacity-45"
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
