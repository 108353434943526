'use client';
import { Spinner } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UseCreateMemberPassword, UseUserTypes } from '../services/Query';
import sign_up_logo from '../assets/images/signup_logo.png';
import lock_icon from '../assets/images/lock-privacy.svg';
import show_icon from '../assets/images/password_show.svg';
import hide_icon from '../assets/images/password_hide.svg';
import { useNavigate, useParams } from 'react-router-dom';
import TokenService from '../store/token';

type userFrm = {
  password: string;
  confirm_password: string;
};
const nickNameValidate = (nickName: string): boolean => {
  let bSuccess = true;
  let upperCaseLetters = /[^a-zA-Z0-9(.|\-|@|_)]/g;
  if (upperCaseLetters.test(nickName)) {
    bSuccess = false;
  }
  return bSuccess;
};
const passwordValidate = (password: string): boolean => {
  let bSuccess = true;

  let Letters = /[A-Z]/g;
  if (!Letters.test(password)) {
    bSuccess = false;
  }
  Letters = /[a-z]/g;
  if (!Letters.test(password)) {
    bSuccess = false;
  }
  Letters = /[0-9]/g;
  if (!Letters.test(password)) {
    bSuccess = false;
  }
  const format = /\W|_/g;
  if (!format.test(password)) {
    bSuccess = false;
  }
  return bSuccess;
};
export const CreatePassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { mutate: saveMemberPassword, isLoading: isSaving } =
    UseCreateMemberPassword();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {
    register,
    getValues,
    reset,
    watch,
    formState: { isValid, dirtyFields, errors },
  } = useForm<userFrm>({
    mode: 'onChange',
  });
  useEffect(() => {
    if (token) TokenService.updateLocalAccessToken(token);
  }, [token]);

  const saveUserPassword = () => {
    const data = getValues();
    saveMemberPassword(JSON.stringify(data), {
      onSuccess: () => {
        navigate('/signin');
      },
      onError: () => {
        alert('this signup is failed!');
      },
    });
  };

  return (
    <div className="flex  items-center md:md-h-vw-port xl:xl-h-vw-port py-3 font-nunito">
      <div className="flex max-md:flex-col md:flex-row justify-center w-full py-8 md:space-x-10 max-md:space-y-4">
        <div>
          <img
            src={sign_up_logo}
            alt=""
            className="xl:w-full md:w-[45vw] w-[80vw] max-md:mx-auto"
          />
        </div>
        <div className="flex items-center max-md:justify-center">
          <div className="max-md:w-[90vw] md:w-[45vw] xl:w-[460px] space-y-5">
            <h5 className="text-2xl xl:text-[40px] font-bold text-[#0098DB]">
              Password Creation
            </h5>
            <h6 className="font-medium text-xl max-md:font-light text-[#232429]">
              Please Create Password
            </h6>
            <div className="text-base text-gray-500">
              <ul>
                  <li>Password length must be between 8 to 30.</li>
                  <li>Password must contain at least one uppercase letter.</li>
                  <li>Password must contain at least one lowercase letter.</li>
                  <li>Password must contain at least one number and at least one symbol: (1~9, !, @, #, $, %, ^, &, *)</li>
              </ul>
              {/* Length must be between 8-30 and contain at least one
              uppercase letter, at least one lowercase letter, at least one
              number and at least one symbol. */}
            </div>
            <div className="bg-[#F0EFF1] py-3 flex px-4 shadow-lg">
              <div className="flex items-center">
                <img src={lock_icon} alt="" />
              </div>
              <div className="px-2 grow">
                <span className="text-[#ADADAD] font-light block">
                  Password
                </span>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="p-0 bg-transparent border-0 focus:ring-0"
                  {...register('password', {
                    required: true,
                    minLength: 8,
                    maxLength: 30,
                    validate: (val: string) => {
                      const passwordRegex =
                        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,30}$/g;
                      if (!passwordRegex.test(val)) return 'not matched rules';
                    },
                  })}
                />
              </div>
              <div className="flex items-center">
                <img
                  src={showPassword ? hide_icon : show_icon}
                  alt=""
                  onClick={() => setShowPassword((prev) => !prev)}
                />
              </div>
            </div>
            <div className="bg-[#F0EFF1] py-3 flex px-4 shadow-lg">
              <div className="flex items-center">
                <img src={lock_icon} alt="" />
              </div>
              <div className="px-2 grow">
                <span className="text-[#ADADAD] font-light block">
                  Confirm Password
                </span>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  className="p-0 bg-transparent border-0 focus:ring-0"
                  {...register('confirm_password', {
                    required: true,
                    validate: (val: string) => {
                      if (watch('password') !== val)
                        return 'password does not match';
                    },
                  })}
                />
              </div>
              <div className="flex items-center">
                <img
                  src={showConfirmPassword ? hide_icon : show_icon}
                  alt=""
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                />
              </div>
            </div>

            {/* <div className="text-lg text-[#B00303] text-center">
            This is an example error message for inputs
          </div> */}
            <div>
              <button
                className="w-full bg-[#66B929] text-white font-bold text-lg py-3 rounded-md shadow-sm disabled:opacity-35"
                disabled={!isValid}
                onClick={saveUserPassword}
              >
                {isSaving && (
                  <Spinner color="info" className="me-2" size={'sm'} />
                )}
                Create Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
