"use client";
import augmented_reality from "../assets/images/augmented_reality.svg";
import mac_icon from "../assets/images/apple_icon.svg";
import window_icon from "../assets/images/windows_icon.svg";
import oculus from "../assets/images/logo_oculus.svg";
import oculus_png from "../assets/images/logo_oculus.png";
import landing_icon_0 from "../assets/images/landing_logo_1.png";
import landing_icon_1 from "../assets/images/landing_logo_2.png";
import landing_icon_2 from "../assets/images/landing_logo_3.png";

import arrow_left from "../assets/images/circle_chevron_left.svg";
import arrow_right from "../assets/images/circle_chevron_right.svg";
import innovation from "../assets/images/idea-svgrepo-com.svg";
import accessibility from "../assets/images/accessibility.svg";
import environment_icon from "../assets/images/environments.svg";
import engaging from "../assets/images/engaging.svg";
import anonymous from "../assets/images/anonymous.svg";
import effective from "../assets/images/effective.svg";
import partnership from "../assets/images/partnership_logo.png";
import landing_diagram from "../assets/images/landing_diagram.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import oasis from "../assets/images/oasis.png";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { UseSendPartnerEmail } from "../services/Query";
import { Modal, Spinner } from "flowbite-react";

type ItemProp = {
  icon: string;
  title: string;
  desc: string;
};
type PartnerFrm = {
  firstName: string;
  lastName: string;
  organization: string;
  email: string;
  message: string;
};

const WellItem = (props: ItemProp) => {
  const { title, desc, icon } = props;
  return (
    <div className="bg-white rounded-lg max-md:w-[330px] xl:w-[280px] p-5">
      <div>
        <div className="flex justify-center mb-3 md:justify-center">
          <img src={icon} alt="" />
        </div>
        <div className="flex justify-center">
          <div className="space-y-2 ">
            <h6 className="text-xl text-[#0098DB] text-center">{title}</h6>
            <p className="text-base text-[#232429]">{desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export const Landing = () => {
  const sliderRef = useRef<Slider | null>(null);
  const {
    mutate: sendPartnerEmail,
    isLoading: isSending,
  } = UseSendPartnerEmail();
  const {
    register,
    getValues,
    reset,
    watch,
    formState: { isValid },
  } = useForm<PartnerFrm>({
    mode: "onChange",
  });
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const onSendPartnerEmail = () => {
    const data = getValues();
    sendPartnerEmail(JSON.stringify(data), {
      onSuccess: () => {
        setShowMessage(true);
      },
      onError: () => {},
    });
    reset();
  };
  const [showMessage, setShowMessage] = useState(false);
  return (
    <>
      <div className="pb-8 space-y-8">
        <section className="max-md:hidden md:landing_hero_bg_md xl:landing_hero_bg w-full md:h-[17vw] xl:h-[17vw] flex items-end justify-end">
          <div className="flex justify-end font-nunito">
            {/* <div className="start_nw text-white text-2xl font-bold h-[163px] flex items-center w-[173px] justify-center">
            Start Now
          </div>
          <div className="experience_bg text-white text-2xl font-bold h-[163px] w-[556px] flex items-center ">
            <div className="ps-4">
              <div>Serenaverse</div>
              <div>Experience Immersive Addiction Support</div>
            </div>
          </div> */}
          </div>
        </section>
        <section className="flex flex-col justify-center xl:py-8 md:py-4 md:space-x-5 md:flex-row font-nunito max-md:space-y-4 max-md:px-4">
          <div className="md:w-[40vw] xl:w-[28vw] flex items-center justify-center">
            <img src={augmented_reality} className="xl:w-[536px] md:w-[336px]" alt="Virtual Mental Health and Wellness"/>
          </div>
          <div className="flex items-center">
            <div className="xl:w-[30vw] md:w-[28vw] space-y-3">
              <h6 className="text-[#0098DB] font-bold text-xl md:text-4xl">
                Virtual Mental Health and Wellness
              </h6>
              <p className="font-light">
                Serenaverse was developed for people seeking more accessible and
                more effective mental health and wellness resources. By
                leveraging virtual reality technology, users can enjoy an
                immersive experience in a safe place where they can socialize
                with like-minded peers, receive support or counseling, get
                access to information and educational resources, and attend
                support groups from the comfort of their own homes. Dedicated
                spaces are set up for one-on-one meetings, group sessions, and
                socialization. You can explore Serenaverse with a virtual
                reality headset or from your computer or mobile device.
              </p>
              <h6 className="text-[#66B929] text-xl font-bold">
                Join our community today
              </h6>
              <div className="flex text-lg text-[#0098DB] space-x-8">
                {/* <div className="flex">
              <div>
                <img src={mac_icon} alt="" />
              </div>
              <span className="font-bold">macOS</span>
            </div> */}
                <div className="flex space-x-2">
                  <div>
                    <img src={window_icon} alt="Download window version of the app" />
                  </div>
                  <span className="font-bold">Windows</span>
                </div>
                {/* <div className="flex space-x-2">
                <div>
                  <img src={oculus_png} alt="" width={40} height={24} />
                </div>
                <span className="font-bold">Oculus</span>
              </div> */}
              </div>
            </div>
          </div>
        </section>
        <section className="xl:py-8 md:py-4 font-nunito">
          <img
            src={landing_diagram}
            alt="Infographic of workflow for the Serenaverse app"
            className="block mx-auto md:w-[80vw] xl:w-[66vw] max-xl:hidden"
          />
          <div className="grid grid-cols-1 px-4 md:px-8 xl:hidden md:grid-cols-2 gap-y-4">
            <div className="">
              <h6 className="text-[#3DBBEB] font-bold text-start md:text-2xl">
                Mindfulness & Wellness Activities
              </h6>
              <p className="font-light ">
                Access to services such as guided meditation and music therapy
                to help soothe and calm the mind. Transport yourself to a world
                free of everyday stresses where you can relax and unwind.
              </p>
            </div>
            <div className="max-md:hidden"></div>
            <div className="max-md:hidden"></div>
            <div>
              <h6 className="text-[#981C35] font-bold text-start md:text-2xl">
                Informational & Educational Resources{" "}
              </h6>
              <p className="font-light ">
                Find information about mental health and wellness from some of
                the leading organizations in the field. Interact and ask
                questions or just browse and educate yourself.
              </p>
            </div>
            <div>
              <h6 className="text-[#BF7301] font-bold text-start md:text-2xl">
                Peer Support & Social Networking
              </h6>
              <p className="font-light ">
                Find information about mental health and wellness from some of
                the leading organizations in the field. Interact and ask
                questions or just browse and educate yourself.
              </p>
            </div>
            <div className="max-md:hidden"></div>
            <div className="max-md:hidden"></div>
            <div>
              <h6 className="text-[#27734D] font-bold text-start md:text-2xl">
                Virtual Counseling & Therapy
              </h6>
              <p className="font-light ">
                Make an appointment with a licensed therapist for a one-on-one
                session when you can't (or don't want to) make the trip to their
                office. There are a variety of options for counseling sessions.
              </p>
            </div>
            <div>
              <h6 className="text-[#4646DF] font-bold text-start md:text-2xl">
                Predictive Behavior Technology Using AI
              </h6>
              <p className="font-light ">
                Coming soon! Give therapists and counselors the technology to
                help guide you in a more efficient way. Nonverbal cues can be
                analyzed and help recognize behavioral patterns and allow
                professionals to better treat you.
              </p>
            </div>
            <div className="max-md:hidden"></div>
          </div>
        </section>
        <section className="flex flex-col-reverse justify-center xl:py-8 md:py-4 md:space-x-5 md:flex-row font-nunito">
          <div className="xl:w-[28vw] md:w-[40vw] flex items-center max-md:justify-center">
            <div className="space-y-5">
              <h6 className="text-[#0098DB] md:text-2xl font-bold max-md:w-[80vw]">
                Users can transport themselves to a variety of different
                settings with features to help accomplish their goals, such as
                outdoors atop a peaceful mountain.
              </h6>
              <div className="flex max-md:hidden">
                <img
                  src={arrow_left}
                  alt="arrow left" // Remove the domain from a URL string.
                  onClick={() => {
                    sliderRef.current?.slickPrev();
                  }}
                />
                <img
                  src={arrow_right}
                  alt="arrow right"
                  onClick={() => {
                    sliderRef.current?.slickNext();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="max-md:flex max-md:flex-col max-md:justify-center max-md:mb-4">
            <div className="xl:w-[28vw] md:w-[40vw] max-md:w-[100vw]">
              <Slider
                {...settings}
                className=""
                ref={(slider) => {
                  sliderRef.current = slider;
                }}
                autoplay={true}
                autoplaySpeed={4000}
              >
                <div>
                  <img
                    src={landing_icon_0}
                    alt="vr example image - 1"
                    className="md:w-[45vw] xl:w-[35vw] w-[80vw] mx-auto rounded-lg xl:rounded-xl"
                  />
                </div>
                <div>
                  <img
                    src={landing_icon_1}
                    alt="vr example image - 2"
                    className="md:w-[45vw] xl:w-[35vw] w-[80vw] mx-auto rounded-lg xl:rounded-xl"
                  />
                </div>
                <div>
                  <img
                    src={landing_icon_2}
                    alt="vr example image - 3"
                    className="md:w-[45vw] xl:w-[35vw] w-[80vw] mx-auto rounded-lg xl:rounded-xl"
                  />
                </div>
              </Slider>
            </div>
            <div>
              {/* <img
              src={landing_icon_0}
              alt=""
              className="md:w-[45vw] xl:w-full w-[80vw]"
            /> */}
              <div className="flex mt-2 space-x-4 max-md:justify-center md:hidden">
                <img
                  src={arrow_left}
                  alt="left arrow"
                  onClick={() => {
                    sliderRef.current?.slickPrev();
                  }}
                />
                <img
                  src={arrow_right}
                  alt="right arrow"
                  onClick={() => {
                    sliderRef.current?.slickNext();
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="flex justify-center xl:py-8 md:py-4 font-nunito">
          <div className="xl:w-[1360px] wellness_bg py-8 space-y-4 xl:rounded-xl xl:mx-10">
            <h6 className="text-2xl font-bold text-center text-white">
              The Next Generation of Mental Health and Wellness Support
            </h6>
            <p className="text-center text-white md:text-xl max-md:px-4">
              Virtual Reality is an Innovative and New Medium for an immersive
              experience
            </p>
            <div className="flex justify-center">
              <div className="grid md:grid-cols-3 place-content-center xl:gap-6 md:gap-4 md:px-4 max-md:gap-y-4">
                <WellItem
                  icon={innovation}
                  title="Innovation"
                  desc="Leverage VR for a better and more connected experience for recovery or to facilitate self or group support sessions"
                />
                <WellItem
                  icon={accessibility}
                  title="Accessibility"
                  desc="At any time with VR, computer or mobile device and in the comfort of their own home people can access a community with similar challenges, goals, and desired outcomes"
                />
                <WellItem
                  icon={environment_icon}
                  title="Environments"
                  desc="Doctors, therapists and facilitators can reserve a variety of VR environments, like a classroom or a mountain top to host individual sessions or peer support groups"
                />
                <WellItem
                  icon={engaging}
                  title="Engaging"
                  desc="Our virtual reality environments are custom designed with robust imagery, sound effects, space to explore, and areas to socialize as a community "
                />
                <WellItem
                  icon={anonymous}
                  title="Anonymous"
                  desc="At no time is data or information shared via our platform. A safe space starts with private and anonymous information"
                />
                <WellItem
                  icon={effective}
                  title="Effective"
                  desc="Based on numerous presentations and controlled studies, Serenaverse is effective for better support and group services"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="font-nunito max-xl:flex-col flex justify-center md:space-x-5 max-xl:space-y-5 border xl:border-y max-md:border-y md:mx-5 max-md:mx-0 xl:mx-0 border-[#66B929] xl:py-8 py-5 max-md:border-x-0 xl:border-x-0">
          <div className="max-md:flex max-md:justify-center">
            <img
              src={partnership}
              alt="Partnerships"
              className="md:w-[368px] md:mx-auto max-md:w-[70vw]"
            />
          </div>
          <div className="xl:w-[800px] space-y-4">
            <h6 className="text-[#0098DB] font-bold text-xl md:text-4xl max-xl:text-center xl:mt-4">
              Partnerships
            </h6>
            <p className="font-light text-[#232429] xl:text-start md:text-center max-md:text-center max-md:px-3">
              Serenaverse is seeking to partner with organizations that help
              support mental health and wellness. We can offer a variety of
              benefits to your group and provide a space for your organization
              to set up shop in the Metaverse. Please send us a message for more
              information on partnership opportunities.
            </p>
            <div className="flex flex-col md:flex-row">
              <div className="space-y-4 md:w-2/5 pe-5 max-md:px-4">
                <input
                  className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
                  placeholder="First Name"
                  {...register("firstName", { required: true })}
                />
                <input
                  className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
                  placeholder="Last Name"
                  {...register("lastName", { required: true })}
                />
                <input
                  className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
                  placeholder="Organization Name"
                  {...register("organization", { required: true })}
                />
                <input
                  className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
                  placeholder="Email Address"
                  {...register("email", { required: true })}
                />
              </div>
              <div className="xl:w-3/5 md:w-[50vw] max-md:px-4 max-md:mt-4">
                <textarea
                  className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
                  rows={8}
                  {...register("message", { required: true })}
                ></textarea>
              </div>
            </div>
            <div className="flex max-xl:justify-center">
              <button
                className="bg-[#66B929] rounded-md text-white px-5 py-3 mt-5 font-bold text-lg disabled:opacity-45"
                disabled={!isValid}
                onClick={onSendPartnerEmail}
              >
                {isSending && (
                  <Spinner color="info" className="me-2" size={"sm"} />
                )}
                Request Information
              </button>
            </div>
          </div>
        </section>
        {/* <section className="py-4 space-y-4 font-nunito">
        <div className="flex justify-center">
          <img src={oasis} alt="" />
        </div>
        <h6 className="font-bold text-[#0098DB] text-center text-xl">
          Our Support
        </h6>
        <h5 className="font-bold text-[#0098DB] text-center text-4xl">
          Administrator Support & Access
        </h5>
        <div className="flex justify-center">
          <p className="w-[1000px] text-lg font-light text-center">
            Therapists, doctors, mentors, and other professionals who tested
            OASIS viability, found the platform very effective and enjoyable for
            all participants. By scheduling private peer support sessions,
            professionals have the ability to invite patients to a one-on-one or
            group session in the VR world. Each VR session that is booked by a
            professional is private to ensure anonymity and closed off to other
            people in the world. Help our community of recovering addicts today.
          </p>
        </div>
      </section>
      <section className="font-nunito border border-y border-[#0098DB] py-5 mb-7">
        <div className="space-y-8">
          <h5 className=" font-bold text-4xl text-[#0098DB] text-center underline underline-offset-[15px] decoration-4">
            Send Us A Message
          </h5>
          <h6 className="text-[#0098DB] text-lg text-center">
            Send Us Any Questions Or Concerns
          </h6>
        </div>
        <div className="w-[644px] mx-auto grid grid-cols-2 gap-5 mt-5">
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="First Name"
            />
          </div>
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Last Name"
            />
          </div>
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Subject"
            />
          </div>
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Email Address"
            />
          </div>
          <div className="col-span-2 ">
            <textarea
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Your Message"
              rows={5}
            ></textarea>
          </div>
          <div className="col-span-2">
            <button className="bg-[#0098DB] text-white text-lg w-full py-2 rounded-md">
              Contact
            </button>
          </div>
        </div>
      </section> */}
      </div>
      <Modal
        show={showMessage}
        dismissible
        size={"md"}
        onClose={() => setShowMessage(false)}
      >
        <Modal.Body className="space-y-4 font-nunito">
        <h6 className="text-[#0098DB] text-xl font-bold text-center">
            Thank you for contacting us!
          </h6>
          <p className="font-normal text-center">
            We appreciate your interest in Serenaverse. We will get back in
            touch with you soon!
          </p>
        </Modal.Body>
        <Modal.Footer
          theme={{
            base: "flex justify-center py-3 border-0",
            popup: "border-0",
          }}
          className="font-nunito"
        >
          <button
            onClick={() => setShowMessage(false)}
            className="bg-[#66B929] rounded-md text-white px-8 py-2 font-bold text-lg disabled:opacity-45"
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
