import footer_logo from '../assets/images/footer_logo.png';
import { Link, useNavigate } from 'react-router-dom';
export const Footer = () => {
  return (
    <footer className={`bg-[#36A9E1] py-8 space-y-4`}>
      <div className="w-full">
        <img src={footer_logo} alt="" className="block mx-auto" />
      </div>
      <div className="flex justify-center text-white xl:space-x-8 md:space-x-4 space-x-3">
        <Link to={'landing'}>Home</Link>
        <Link to={'contact'}>Contact Us</Link>
        {/* <Link to={'news'}>News</Link>
        <Link to={'career'}>Careers</Link> */}
        <Link to={'download'}>Downloads</Link>
      </div>
      <div>
        <p className="text-white mx-auto xl:w-[50vw] md:w-[85vw] text-center w-[90vw]">
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum. */}
        </p>
      </div>
    </footer>
  );
};
