import unselected_circle from "../assets/images/unselected_circle_icon.svg";
import unselected_arrow from "../assets/images/unselected_arrow_icon.svg";
import selected_circle from "../assets/images/selected_circle_icon.svg";
import selected_arrow from "../assets/images/selected_arrow_icon.svg";
import not_found from "../assets/images/page_not_found.png";
import { useState } from "react";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <div className="py-4 space-y-8 font-nunito md:md-not-found-h-vw-port">
      <section className="space-y-4 max-md:px-3">
        <div className="flex justify-center">
          <img src={not_found} alt="" className="max-md:w-[302px]" />
        </div>
        <h6 className="xl:text-5xl md:text-3xl font-bold text-[#0098DB] text-center">
          404 Page not found.
        </h6>
        <p className="text-center text-[#3F3D56]">
          Here are some helpful links instead:
          <br />
          <br />
          Home: Return to the Homepage and start from there. <br />
          Support: If you need further assistance, please{" "}
          <Link to={"/contact"}>Contact Us</Link> <br />
          <br />
          We apologize for the inconvenience!
        </p>
      </section>
      {/* <section className="py-4 space-y-4 font-nunito">
        <div className="flex justify-center">
          <img src={oasis} alt="" />
        </div>
        <h6 className="font-bold text-[#0098DB] text-center text-xl">
          Our Support
        </h6>
        <h5 className="font-bold text-[#0098DB] text-center text-4xl">
          Administrator Support & Access
        </h5>
        <div className="flex justify-center">
          <p className="w-[1000px] text-lg font-light text-center">
            Therapists, doctors, mentors, and other professionals who tested
            OASIS viability, found the platform very effective and enjoyable for
            all participants. By scheduling private peer support sessions,
            professionals have the ability to invite patients to a one-on-one or
            group session in the VR world. Each VR session that is booked by a
            professional is private to ensure anonymity and closed off to other
            people in the world. Help our community of recovering addicts today.
          </p>
        </div>
      </section>
      <section className="font-nunito border border-y border-[#0098DB] py-5 mb-7">
        <div className="space-y-8">
          <h5 className=" font-bold text-4xl text-[#0098DB] text-center underline underline-offset-[15px] decoration-4">
            Send Us A Message
          </h5>
          <h6 className="text-[#0098DB] text-lg text-center">
            Send Us Any Questions Or Concerns
          </h6>
        </div>
        <div className="w-[644px] mx-auto grid grid-cols-2 gap-5 mt-5">
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="First Name"
            />
          </div>
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Last Name"
            />
          </div>
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Subject"
            />
          </div>
          <div>
            <input
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Email Address"
            />
          </div>
          <div className="col-span-2 ">
            <textarea
              className="border-[#0098DB] border px-4 py-2 rounded-md w-full"
              placeholder="Your Message"
              rows={5}
            ></textarea>
          </div>
          <div className="col-span-2">
            <button className="bg-[#0098DB] text-white text-lg w-full py-2 rounded-md">
              Contact
            </button>
          </div>
        </div>
      </section> */}
    </div>
  );
};
