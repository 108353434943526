import { UseSignupMemberInfo } from '../services/Query';
import confirm_logo from '../assets/images/confirm_email_logo.png';
import { useNavigate } from 'react-router-dom';
export const EmailConfirm = () => {
  const navigate = useNavigate();
  const { mutate: saveMember, isLoading: isSaving } = UseSignupMemberInfo();

  return (
    <div className="flex items-center py-3 font-nunito menu_h">
      <div className="flex justify-center w-full py-8 space-x-10">
        <div className="w-[90vw] md:w-[80vw] xl:w-[860px] space-y-8 py-5">
          <div className="">
            <img src={confirm_logo} alt="" />
          </div>
          <div className="text-[#0098DB] justify-center  p-6 rounded-t-lg font-bold flex space-x-3">
            <div>
              <svg
                width="76"
                height="75"
                className='max-md:w-[32px]'
                viewBox="0 0 76 75"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M38 68.7501C20.749 68.7311 6.76894 54.7511 6.75 37.5001V36.8751C7.09354 19.7017 21.2331 6.02492 38.4083 6.25281C55.5836 6.48069 69.3553 20.5278 69.2431 37.7042C69.1309 54.8806 55.1768 68.7466 38 68.7501ZM23.6562 36.2188L19.25 40.6251L31.75 53.1251L56.75 28.1251L52.3438 23.6876L31.75 44.2813L23.6562 36.2188Z"
                  fill="#29A64A"
                />
              </svg>
            </div>
            <div className="flex text-xl items-center xl:text-3xl md:text-2xl">
              Email Confirmation Sent
            </div>
          </div>
          <div className="space-y-2 ">
            <div className="text-center font-semibold text-sm md:text-xl text-[#232429]">
              Please check your email, including spam/junk folders to confirm
              your email
            </div>
            <p className="text-[#646464] text-center text-sm md:text-xl">
              Email may take up to 10 minutes to be delivered
            </p>
          </div>
          <div className="mx-auto md:w-[450px] space-y-4">
            <div>
              <button className="w-full py-4 bg-[#66B929] text-white rounded-lg md:text-lg font-bold"
                onClick={() => {
                  navigate('/');
                }}
              >
                Go Home
              </button>
            </div>
            <div className="text-[#646464] text-center md:text-xl">
              Didn't receive confirmation email?
            </div>
            {/* <div>
              <button className="w-full py-4 text-[#0098DB] bg-white border border-[#0098DB] rounded-lg md:text-lg font-bold">
                Go Resend Email Confirmation
              </button>
            </div> */}
          </div>
          <div className="text-center font-semibold text-sm md:text-xl text-[#232429]">
            Please contact at{" "}<a className='underline' href={"mailto:support@serenaverse.com"}>support@serenaverse.com.</a>
          </div>
        </div>
      </div>
    </div>
  );
};
